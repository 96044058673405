import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { IGrupos } from '../../models/IGrupos.model';
import { FormatText } from './../../util/formt-text';
import { CadastroApiService } from './../service/cadastro-api.service';
import { faEdit, faTrash, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IEmpresas } from 'src/app/models/IEmpresas.model';

declare let $: any;

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  faEdit = faEdit;
  faPlus = faPlus;
  faTrash = faTrash;
  faTimes = faTimes;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  grupos: IGrupos[] = [];
  grupoAtual: IGrupos;


  lojas: IEmpresas[] = [];
  lojasAtual: IEmpresas[]  = [];
  lojaAtual: string;

  id_grupo: number = null;
  nomeGrupo: string = "";
  status: string = "";
  statusValores: any[] = [{nome: 'ATIVO', valor: 'A'},{nome: 'INATIVO', valor: 'I'}];
  tipoAtual: string;

  constructor(
    private serviceApi: CadastroApiService,
    private formatTextApi: FormatText
  ) { }

  ngOnInit(): void {

    this.tipoAtual = 'I';
    $('#buttonSalvar').prop("disabled",true);

    this.loadMasks();
    this.configDataTable();
    this.buscaDados();
  }

  buscaDados(){
    this.serviceApi.getDados('get_groups_portal').then(response => {
      this.grupos = response.grupos;
      this.rerender();
    });

  }

  async salvar(){

    if(this.nomeGrupo && this.status){
      this.grupoAtual = { 
        id_grupo: this.id_grupo,
        nome: this.nomeGrupo,
        // cnpj: this.cnpjMatriz.replace(/[\/\*\|\.\<\>\?\"-]/gi, ''),
        status: this.status,
        empresas: this.lojasAtual,
      }
  
      await this.serviceApi.postDadosGrupos(this.grupoAtual, this.tipoAtual).then(res =>{
        if(res['success']){
          this.buscaDados();
          this.limparDados();
        }
      });
    }

  }

  inserir(){
    this.limparDados();
    $('#buttonSalvar').prop("disabled",false);
    $('#inputGrupo').focus();
  }

  limparDados(){
    this.id_grupo = null;
    this.nomeGrupo = '';
    this.status = '';
    this.tipoAtual = 'I';
    this.lojasAtual = [];
  }

  editar(idGrupo){
    var grupoSelecionado = this.grupos.filter(e => e.id_grupo == idGrupo)[0];
    this.tipoAtual = 'E';
    this.id_grupo = grupoSelecionado.id_grupo;
    this.nomeGrupo = grupoSelecionado.nome;
    this.status = grupoSelecionado.status;
    this.lojasAtual = grupoSelecionado.empresas;

    $('#buttonSalvar').prop("disabled",false);
  }

  modalPergunta(idGrupo){
    var grupoSelecionado = this.grupos.filter(e => e.id_grupo == idGrupo)[0];
    this.tipoAtual = 'D';
    this.id_grupo = grupoSelecionado.id_grupo;
    this.nomeGrupo = grupoSelecionado.nome;
    this.status = grupoSelecionado.status;

    $('#ModalExcluir').modal('show');
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.id_grupo = null;
      this.nomeGrupo = '';
      this.status = 'A';
      this.tipoAtual = 'I';
      this.lojasAtual = [];
    }
    $('#ModalExcluir').modal('toggle');
  }

  removeEmpresa(idCliente){
    var grupoSelecionado = this.grupos.filter(e => e.id_grupo == this.id_grupo)[0];
    if(grupoSelecionado){
      this.lojasAtual = this.lojasAtual.filter(e => e.id_cliente != idCliente);
    }
  }

  addEmpresa(){
    this.serviceApi.getDados('get_companies_portal').then(response => {
      this.lojas = response.empresas;
      $('#ModalAddEmpresa').modal('show');
    });
  }

  adicionaEmpresa(adiciona){
    if(adiciona){
      var grupoSelecionado = this.grupos.filter(e => e.id_grupo == this.id_grupo)[0];
      if(!grupoSelecionado){
        let lojaSelecionada = this.lojas.filter(e => e.id_cliente == this.lojaAtual)[0];
        this.lojasAtual.push(lojaSelecionada);
        $('#ModalAddEmpresa').modal('toggle');
      }else{
        if(grupoSelecionado.empresas.filter(e => e.id_cliente == this.lojaAtual)[0]){
          alert('Essa empresa ja pertence ao grupo!');
        }else{
  
          let lojaSelecionada = this.lojas.filter(e => e.id_cliente == this.lojaAtual)[0];
          
          this.lojasAtual.push(lojaSelecionada);
          
          $('#ModalAddEmpresa').modal('toggle');
        }
      }
    }
  }

  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 3,
      scrollY: "350px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadMasks(){
    $("#cpfCad").keydown(function(){
      try {
        $("#cpfCad").unmask();
      } catch (e) {}
  
      $("#cpfCad").mask("999.999.999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#cnpjCad").keydown(function(){
      try {
          $("#cnpjCad").unmask();
      } catch (e) {}
  
      $("#cnpjCad").mask("99.999.999/9999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#telefoneCad").keydown(function(){
      try {
          $("#telefoneCad").unmask();
      } catch (e) {}
  
      $("#telefoneCad").mask("(99)9999-999999");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });
  }
}
