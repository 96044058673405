import { MenuApiService } from './service/menu-api.service';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { User } from './../models/user.model';
import { IMenuUser } from './../models/IMenuUser.model';
import { Encryption } from './../util/encryption';
import { SelecionaClienteComponent } from './../seleciona-cliente/seleciona-cliente.component';
import { faThLarge, faBuilding, faAngleDown, faBars, faChartBar, faTable, faAddressCard, faTasks, faCog, faSignOutAlt, faExclamationCircle, faThumbtack, faBoxesPacking } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

type IMenus = {
  idMenu: string;
  classNivel: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild(SelecionaClienteComponent)
  modalHtml: SelecionaClienteComponent;

  static emitirTrocaDistribuidora = new EventEmitter<string>();
  static emitirTrocaPergunta = new EventEmitter<string>();

  ativado = false;
  infoUser: User;
  infoMenuUser: IMenuUser;
  menusUsados: IMenus[] = [];

  modalSelectCliente: boolean = false;
  nomeFantasiaClienteSelecionado: string;
  ennderoClienteSelecionado: string;
  nivel: string = '0';

  faCog = faCog;
  faBars = faBars;
  faTable = faTable;
  faTasks = faTasks;
  faThLarge = faThLarge;
  faBuilding = faBuilding;
  faChartBar = faChartBar;
  faThumbtack = faThumbtack;
  faAngleDown = faAngleDown;
  faSignOutAlt = faSignOutAlt;
  faAddressCard = faAddressCard;
  faBoxesPacking = faBoxesPacking;
  faExclamationCircle = faExclamationCircle;


  constructor(
    private router: Router,
    private encryptionApi: Encryption,
    private apiMenu: MenuApiService
  ) { }

  ngOnInit(): void {
    SelecionaClienteComponent.emitirSelecionaCliente.subscribe(clienteSelecionado => {
      this.dadosUser();
    })
    sessionStorage.setItem('etl@:menusAnteriores','');

    this.dadosUser();
    this.abreMenu();
  }

  dropdowOpen = function(id){
    if($('#'+id).hasClass('show')){
      $('#'+id).removeClass('show');
    }else{
      $('#'+id).addClass('show');
    }
  }

  redireciona = function(id){
    if(!id){
      
    }
    this.router.navigate(['/'+id]);

  }

  filtroClienteSelecionado(){
    this.modalHtml.
    openModal();
  }

  espandeMenu(menu){
    if($('.'+menu).hasClass('showMenu')){
      $('.'+menu).removeClass('showMenu');
    }else{
      $('.'+menu).addClass('showMenu');
    }
  }

  espandeSubMenu(menu, classe){
    var sessionMenus = sessionStorage.getItem('etl@:menusAnteriores');
    var menuDesativado = '';
    if(sessionMenus){
      /*
        Se o Menu Existir no mesmo nivel fecha o menu anterior do mesmo nivel
        caso seja de outro nivel nao mexe
      */
      this.menusUsados = JSON.parse(sessionMenus);

      this.menusUsados.map(res => {
        if(res.idMenu!=menu && res.classNivel == classe){
          $('#'+res.idMenu).removeClass('showMenu');
          $('#'+res.idMenu+' .'+res.classNivel).css('display','none');
          $('#'+res.idMenu+' .arrow-dashboard').first().removeClass('rotate');
          menuDesativado = res.idMenu;
        }
      })
      this.menusUsados = this.menusUsados.filter(e => e.idMenu != menuDesativado);

      sessionStorage.setItem('etl@:menusAnteriores',JSON.stringify(this.menusUsados)); 
    }
    /*
      Se o IdMenu Ja existir a gente remove ele pois é o click feixando
      Caso o idMenu nao exista adiciona ele a lista
    */
    if(this.menusUsados.filter(e => e.idMenu == menu).length > 0){
      this.menusUsados = this.menusUsados.filter(e => e.idMenu != menu);
    }else{
      this.menusUsados.push({
        idMenu: menu,
        classNivel: classe
      });
    }
    sessionStorage.setItem('etl@:menusAnteriores',JSON.stringify(this.menusUsados));

    if($('#'+menu).hasClass('showMenu')){
      $('#'+menu).removeClass('showMenu');
      $('#'+menu+' .'+classe).css('display','none');
      $('#'+menu+' .arrow-dashboard').first().removeClass('rotate');
      
    }else{
      $('#'+menu).addClass('showMenu');
      $('#'+menu+' .'+classe).css('display','block');
      $('#'+menu+' .arrow-dashboard').first().addClass('rotate');
    }
  }

  espandeSideBar(){
    // if($('.sidebar').hasClass('fechar')){
    //   $('.sidebar').removeClass('fechar');
    //   HeaderComponent.emitirTrocaDistribuidora.emit('open');
    // }else{
    //   $('.sidebar').addClass('fechar');
    //   HeaderComponent.emitirTrocaDistribuidora.emit('close');
    // }
  }

  dadosUser(){
    var infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));
    this.nivel = this.infoUser.nivel;
    if(this.infoUser.clientes.filter(e => e.selecionado).length>1){
      this.nomeFantasiaClienteSelecionado = this.infoUser.clientes.filter(e => e.selecionado).length+" Lojas selecionadas";
      this.ennderoClienteSelecionado = "";
    }else if(this.infoUser.clientes.filter(e => e.selecionado).length == 1){
      this.nomeFantasiaClienteSelecionado = this.infoUser.clientes.filter(e => e.selecionado)[0].razao;
      this.ennderoClienteSelecionado = this.infoUser.clientes.filter(e => e.selecionado)[0].logradouro + " ";
    }else{
      this.nomeFantasiaClienteSelecionado = "Selecione aqui Uma Loja";
      this.ennderoClienteSelecionado = "";
    }
    
  }

  async abreMenu(){
    await this.apiMenu.getMenus();

    var infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoMenuUser'));
    this.infoMenuUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));
  }

  carregaPergunta(idPergunta,config_dash,filter_dash){
    $('.sub-menu').addClass('noHover');
    if(this.router.url != '/home'){
      this.router.navigate(['/home']);
    }
    this.infoUser.descricao_dashboard = idPergunta;
    this.infoUser.config_dash = config_dash;
    this.infoUser.filter_dash = filter_dash;
    var textEncoded = this.encryptionApi.encryptAll(this.infoUser);
      
    sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), textEncoded);

    HeaderComponent.emitirTrocaPergunta.emit(idPergunta);

    setTimeout(() => {
      $('.sub-menu').removeClass('noHover');
    }, 3000)
  }

  homehandle(){
    if(this.router.url != '/home'){
      this.router.navigate(['/home']);
    }else{
      this.infoUser.descricao_dashboard = null;
      var textEncoded = this.encryptionApi.encryptAll(this.infoUser);
      
      sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), textEncoded);

      HeaderComponent.emitirTrocaPergunta.emit();
    }
    
  }
}
