<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #C3C3C3;">
  <div class="row" style="height: 70px;">
      <app-header style="width: 100%;"></app-header>
  </div>
  <div class="row home">
          <div class="container-form">
            <div class="linha-header">
              <h3>Cadastro de Grupos Corporativos</h3>
              <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
            </div>
              <div class="linha-campos">
                  <form id="formGrupos" action="#" ngNativeValidate autocomplete="off">
                      <div class="row">
                          <div class="form-group col-md-4">
                              <label for="inputGrupo">Nome do Grupo</label>
                              <input [(ngModel)]="nomeGrupo" name="nomeGrupo" type="text" class="form-control" id="inputGrupo" placeholder="Nome do Grupo" required>
                          </div>
                          <div class="form-group col-md-4">
                              <label for="inputStatus">Status</label>
                              <select [(ngModel)]="status" name="status" class="form-select" id="inputStatus" required>
                                  <option *ngFor="let item of statusValores" [ngValue]="item.valor">{{ item.nome }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="form-row" style="padding-left: 5px;">
                        <div class="form-group-button">
                          <label for="inputGrupo">Empresas Vinculadas</label>
                          <button type="button" class="btn btn-salvar btn-adicionar" (click)="addEmpresa()"><fa-icon [icon]="faPlus" ></fa-icon></button>
                        </div>
                        <div class="form-group cards-empresas">
                          <div class="card-grupo-empresa" *ngFor="let empresa of lojasAtual">
                            <div class="card-info">
                              <label class="nome-empresa">{{empresa.razao}}</label>
                              <label class="cnpj-empresa">{{this.formatTextApi.formatCnpjCpf(empresa.cnpj)}}</label>
                            </div>
                            <fa-icon [icon]="faTimes" (click)="removeEmpresa(empresa.id_cliente)" ></fa-icon>
                          </div>
                        </div>
                      </div>
                  </form>
                  <div class="controle-buttons">
                    <button (click)="limparDados()" class="btn btn-limpar">Limpar</button>
                    <button (click)="inserir()" class="btn btn-inserir">Inserir</button>
                    <button id="buttonSalvar" (click)="salvar()" class="btn btn-salvar">Salvar</button>
                  </div>
              </div>
              <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
              <div class="linha-dados">
                  <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
                      <thead>
                        <tr>
                          <th style="width: 75px;">ID Grupo</th>
                          <th>Nome Grupo</th>
                          <th style="width: 80px;">Status</th>
                          <th style="width: 80px;">Acoes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let grupo of grupos">
                          <td>{{ grupo.id_grupo }}</td>
                          <td>{{ grupo.nome }}</td>
                          <td>{{ grupo.status=='A'?'ATIVO':'INATIVO' }}</td>
                          <td class="acoes-linha">
                              <button type="button" class="btn btn-primary" (click)="editar(grupo.id_grupo)"><fa-icon [icon]="faEdit"></fa-icon></button>
                              <button type="button" class="btn btn-danger" (click)="modalPergunta(grupo.id_grupo)"><fa-icon [icon]="faTrash"></fa-icon></button>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </div>
  </div>
  <div class="modal" tabindex="-1" role="dialog" id="ModalExcluir">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Excluir registro</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Deseja realmente excluir o registro?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
            <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
  </div>
  <div class="modal" tabindex="-1" role="dialog" id="ModalAddEmpresa">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Empresa</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="inputEmpresa">Empresa</label>
          <select [(ngModel)]="lojaAtual" name="empresa" class="custom-select" id="inputEmpresa" required>
              <option *ngFor="let item of lojas" [ngValue]="item.id_cliente">{{ item.nome_fantasia }}</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="adicionaEmpresa(true)">Adicionar</button>
          <button type="button" class="btn btn-danger" (click)="adicionaEmpresa(false)" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
    <!-- <app-footer></app-footer> -->
</div>


