import { Injectable } from '@angular/core';

import { ApiConfigService } from './../../../sevice/api-config.service';
import { Encryption } from './../../../util/encryption';
import { User } from './../../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AtividadesApiService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  listaOpcoes(empresaSelect: any){

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: this.infoUser.id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_info_geral_filtros',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  totaisAtividades(dataDe: string, dataAte: string, empresaSelect: any, idDepartamento: any = '',  idSecao: any = '', idCategotia: any = '', idSegmento: any = '', idUsuario: string = '', status: string = '', atividade: string = '', alerta: number = 0){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      data_de: dataDe.replace(/-/g,''),
      data_ate: dataAte.replace(/-/g,''),
      id_departamento:  idDepartamento,
      id_secao: idSecao,
      id_categoria: idCategotia,
      id_segmento: idSegmento,
      id_usuario: idUsuario,
      status: status,
      atividade: atividade,
      id_alerta: alerta
    }

    return this.configApi.configServEtl.post<string, any>('get_totais_atividades_portal',data,).then( response => {
      return response.data.totais;
    }).catch( error =>{
      console.log(error);
    })
  }

  logAtividades(dataDe: string, dataAte: string, idDepartamento: any = '',  idSecao: any = '', idCategotia: any = '', idSegmento: any = '', idUsuario: string = '', status: string = '', empresaSelect: any = '', atividade: string = '', alerta: number = 0){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: idUsuario,
      id_departamento: idDepartamento,
      id_secao: idSecao,
      id_categoria: idCategotia,
      id_segmento: idSegmento,
      status: status,
      atividade: atividade,
      id_alerta: alerta,
      data_de: dataDe.replace(/-/g,''),
      data_ate: dataAte.replace(/-/g,'')
    }

    return this.configApi.configServEtl.post<string, any>('get_log_atividades_portal',data,).then( response => {
      return response.data.log_atividades;
    }).catch( error =>{
      console.log(error);
    })
  }

  imagensAtividades(idAtividade: string, idChecklist: number){

    const data = {
      id_atividade: idAtividade,
      id_checklist: idChecklist
    }

    return this.configApi.configServEtl.post<string, any>('get_foto_log_atividades_portal',data,).then( response => {
      return response.data;
    }).catch( error =>{
      console.log(error);
    })
  }
}
