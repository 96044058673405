import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { FormatDate } from 'src/app/util/format-date';
import { AprovaPedidosCheckmeService } from './service/aprova-pedidos-checkme.service';
import Swal from 'sweetalert2';
import { IPedido } from '../../models/IPedidos';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IFornecedores = {
  id_fornecedor: string;
  cnpj: string;
  razao: string;
  fantasia: string;
}

@Component({
  selector: 'app-aprova-pedidos-checkme',
  templateUrl: './aprova-pedidos-checkme.component.html',
  styleUrls: ['./aprova-pedidos-checkme.component.css']
})
export class AprovaPedidosCheckmeComponent implements OnInit {

  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  idPedido: string;
  empresa: string;

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  ativaLoading: boolean = true;
  key: string = 'data_pedido';
  order: string = 'desc';
  matMenuTimer: any;

  input$ = new Subject<string>();
  loading = false;
  termoEscrito = '';

  // empresasGeral: IEmpresas[] = [];
  // empresaSelecionado: string = '';
  // fornecedores: IFornecedores[] = [];
  // fornecedoresBuffer: IFornecedores[] = [];
  // fornecedoresGeral: IFornecedores[] = [];
  // fornecedorSelecionado: string = '';

  idSelecionado: string = '';
  idSelecionadoQuant: string = '';
  idSelecionadoPreco: string = '';
  quantidadeTotal: number = 0;
  valorPedidoTotal: number = 0;
  quantidadeItens: number = 0;
  valorPedidoTotalStr: string;

  listaPedidos: IPedido[] = [];

  bufferSize = 50;

  faAnglesLeft = faAnglesLeft;

  constructor(
    private apiAprovaPed: AprovaPedidosCheckmeService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ativaLoading = true;

    this.route.queryParams
      .subscribe(params => {
        this.idPedido = params.idPedido;
        this.empresa = params.empresa;
      }
    );

    this.carregaDados();

  }

  carregaDados(){
    this.ativaLoading = true;
    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.quantidadeItens = 0;

    this.apiAprovaPed.ListaItemPedido(this.idPedido).then(result => {
      this.listaPedidos = result;
      this.listaPedidos[0].itens.map(e => {

        this.quantidadeTotal += e.quant_ajst_multiplo_confirmada?e.quant_ajst_multiplo_confirmada:e.quant_ajst_multiplo;
        this.valorPedidoTotal += e.total;
        this.quantidadeItens += 1;

      });
      this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      this.ativaLoading = false;
    });
  }

  voltar(){
    this.router.navigate(['/listaPedidosFornecedor']);
  }

  selecionaLinha(id: string){
    this.idSelecionado = id;
  }

  aprovaPedido(){

    let pedido = this.listaPedidos[0];
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido,'A').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then( r => {this.voltar();});
    });
  }

  reprovaPedido(){
    let pedido = this.listaPedidos[0];
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido,'R').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then( r => {this.voltar();});
    });
  }

  ordernar(key){
    if(key==this.key)
      this.order = this.order=='asc'?'desc':'asc';
    else{
      this.order = 'asc';
    }
    this.key = key;
  }

  cardDoubleClick(id:string,tipo): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;

    if(tipo=='quant'){
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = id;
      setTimeout( () => {$('#input_'+this.idSelecionadoQuant).focus().select();}, 400)
    }

    if(tipo=='preco'){
      this.idSelecionadoPreco = id;
      this.idSelecionadoQuant = '';
      setTimeout( () => {$('#input_'+this.idSelecionadoPreco).focus().select();}, 400)
    }


    // let empresaEnv = this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].id_loja_empresa +' - '+ this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].descricao;
    // this.router.navigate(['/pedidoSelecionado'], { queryParams: { idPedido: this.idSelecionado, empresa: empresaEnv } } );
  }

  clickedMe() {
    if (!this.matMenuTimer) return;
  }

  onKeydown(event,tipo) {
    var novoValor = event.target.value;

    if(tipo=='cond'){
      this.listaPedidos[0].prazo_pagamento = novoValor;
      return;
    }

    if(tipo=='escape'){
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = '';
    }

    if(tipo=='quant'){
      this.listaPedidos[0].itens.filter(e => 'quant_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoQuant)[0].quant_pedido_confirmada = parseInt(novoValor);
      this.idSelecionadoQuant = this.listaPedidos[0].itens.filter(e => tipo+'_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoQuant)[0].id_pedido;
    }

    if(tipo=='preco'){
      this.listaPedidos[0].itens.filter(e => 'preco_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoPreco)[0].preco_venda_confirmada = parseFloat(novoValor);
      this.idSelecionadoPreco = this.listaPedidos[0].itens.filter(e => tipo+'_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoPreco)[0].id_pedido;
    }

    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.listaPedidos[0].itens.map(e => {

      this.quantidadeTotal += e.quant_pedido_confirmada?e.quant_pedido_confirmada:e.quant_ajst_multiplo;
      this.valorPedidoTotal += ((e.preco_venda_confirmada?e.preco_venda_confirmada:e.preco_venda)*(e.quant_pedido_confirmada?e.quant_pedido_confirmada:e.quant_ajst_multiplo));

    });

    this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

  }

  salvarPedido(){
    let pedido = this.listaPedidos[0];

    this.ativaLoading = true;
    this.apiAprovaPed.setPedidos(pedido.id_pedido,pedido,'S').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: 'Pedido Salvo'
      }).then( r => {this.voltar();});
    });
  }


}
