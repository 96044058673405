import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Encryption } from './../../util/encryption';
import { ApiConfigService } from 'src/app/sevice/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  private urlEtl2b = 'http://servicos.etl2b.com.br:41650/';
  // private urlEtl2b = 'http://10.200.16.11:5091/';
  

  constructor(
    private http: HttpClient,
    private encryptionApi: Encryption,
    private configApi: ApiConfigService
  ) { }

  async postCredentials(username, password){

    if(username=='etl2b'&& password=='etl2b'){

      sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), this.encryptionApi.encryptAll(JSON.parse('{"usuario": "TJ", "senha": "12345", "telefone": "85986064351", "cpf": "05315779378", "master": "Y", "clientes": [{"cnpj": "03720882000239", "nome": "SAO LUIZ", "nivel": "1","endereco":"rua a","selecionado": true}, {"cnpj": "03720882000310", "nome": "LAGOA", "nivel": "5","endereco":"rua a","selecionado": false}], "success": true}')));

      return await true;
    }

    const headers = {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json", "Access-Control-Allow-Origin": "*"};

    var data = {
      "login": username.toUpperCase(),
      "password": password
    }
    
    var dataBase = this.encryptionApi.encryptAll(data);
    var cadastroJson;

    // const textCript = await this.http.post<string>(`${this.urlEtl2b}get_credentials_portal`, dataBase, {headers, responseType: 'text' as 'json'}).toPromise().then( response => {
      
    //   cadastroJson = JSON.parse(response);
    //   return response;

    // });

    const textCript = await this.configApi.configServEtl.post<string, any>('get_credentials_portal',dataBase,).then(response => {
      cadastroJson = response.data;
      return response;
    })

    if(cadastroJson.success){

      cadastroJson.clientes.forEach((item,key) => {
        if(key==0){
          item['selecionado'] = true;
        }else{
          item['selecionado'] = false;
        }
      });

      var textEncoded = this.encryptionApi.encryptAll(cadastroJson);
      
      sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), textEncoded);

      return true;
    }else{
      return false;
    }
  }
}
