import { FormatDate } from './../../util/format-date';
import { Component, OnInit } from '@angular/core';

import { CadastroApiService } from './../service/cadastro-api.service';
import { AtividadesApiService } from './../../listas/lista-atividades/service/atividades-api.service';

declare let $: any;

type IProdutos ={
  codigo_produto: string;
  descricao: string;
  ean: string;
  eans: string[];
}

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IDepartamentos = {
  id_departamento: string;
  descricao: string;
}

type ISecoes = {
  id_secao: string;
  id_departamento: string;
  descricao: string;
  descricao_departamento: string;
}

type IComboOpcao ={
  id: string;
  descricao: string;
}

type ITipoAtividade ={
  id_atividade: string;
  descricao: string;
}

type IAlertas = {
  id_alerta: number;
  descricao_alerta: string;
  id_atividade: string;
  descricao: string;
}

@Component({
  selector: 'app-cria-atividades',
  templateUrl: './cria-atividades.component.html',
  styleUrls: ['./cria-atividades.component.css']
})
export class CriaAtividadesComponent implements OnInit {

  ativaLoading: boolean = true;

  produtos: IProdutos[] = [];
  produtosAutocomplete: IProdutos[] = [];
  produtosSelecionados: IProdutos[] = [];
  produtoSelecionado:string = '';

  departamentos: IDepartamentos[] = [];

  secoesGeral: ISecoes[] = [];
  secoes: ISecoes[] = [];
  secoesSelecionadas: ISecoes[] = [];
  secaoSelecionado: string = '';

  opcoesCombo: IComboOpcao[] = [{id: '1',descricao: 'Produto'},{id: '2',descricao: 'Departamento + Secao'}];
  opcaoCombo: string = '1';

  empresasGeral: IEmpresas[] = [];
  empresaSelecionado: string = '';

  alertas:IAlertas[] = [];
  alertaSelecionado: string = '';

  dataAgenda:string = '';

  tiposAtividades: ITipoAtividade[] = [{id_atividade: '14',descricao: 'teste'}];
  tiposAtivSelect: string = '';

  ativaAutoCompleteProdutos:boolean = false;
  ativaAutoCompleteSecao:boolean = false

  constructor(
    private serviceApi: CadastroApiService,
    private atividadesApi: AtividadesApiService,
    private formatDate: FormatDate
  ) { }

  ngOnInit(): void {
    this.dataAgenda = this.formatDate.formatDateForInput(new Date(), 1);
    this.carregaDados();
  }

  carregaDados(){

    this.atividadesApi.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.empresasGeral = result.empresas;
      this.departamentos = result.departamentos;
      this.secoesGeral = result.secoes;
      this.secoesGeral.map(e => {
        e.descricao_departamento = this.departamentos.filter(p => p.id_departamento == e.id_departamento)[0].descricao
      })
      this.secoes = this.secoesGeral;
      this.alertas = result.alertas;

      if(this.empresaSelecionado==''){
        this.empresaSelecionado = this.empresasGeral[0].id_cliente+'_'+this.empresasGeral[0].id_empresa+'_'+this.empresasGeral[0].id_loja_empresa;
      }
    });

    this.serviceApi.getListaProdutos('',this.empresaSelecionado).then(result => {
      var ncont = 0;
      this.produtos = result;
      this.produtosAutocomplete = result;
      // this.produtos.every(e => {
      //   ncont++
      //   this.produtosAutocomplete.push(e);
      //   if(ncont>=100){
      //     return false;
      //   }else{
      //     return true;
      //   }
      // });
      this.ativaLoading = false;
    });

    this.trocaServico();
    
  }

  adiciona(tipo,idUm, idDois){
    if(tipo=='produto'){
      this.produtosSelecionados.push(this.produtosAutocomplete.filter(e => e.codigo_produto == idUm)[0]);
      this.produtosAutocomplete = this.produtosAutocomplete.filter(e => e.codigo_produto != idUm);
      this.produtos = this.produtos.filter(e => e.codigo_produto != idUm);
    }else{
      this.secoesSelecionadas.push(this.secoes.filter(e => e.id_departamento == idUm && e.id_secao==idDois)[0]);
      this.secoes = this.secoes.filter(e => e.id_departamento != idUm || e.id_secao!=idDois);
      this.secoesGeral = this.secoesGeral.filter(e => e.id_departamento != idUm || e.id_secao!=idDois);
    }
  }

  remove(tipo,idUm,idDois){
    if(tipo=='produto'){
      this.produtosAutocomplete.push(this.produtosSelecionados.filter(e => e.codigo_produto == idUm)[0]);
      this.produtos.push(this.produtosSelecionados.filter(e => e.codigo_produto == idUm)[0]);
      this.produtosSelecionados = this.produtosSelecionados.filter(e => e.codigo_produto != idUm);
    }else{
      this.secoes.push(this.secoesSelecionadas.filter(e => e.id_departamento == idUm && e.id_secao==idDois)[0]);
      this.secoesGeral.push(this.secoesSelecionadas.filter(e => e.id_departamento == idUm && e.id_secao==idDois)[0]);
      this.secoesSelecionadas = this.secoesSelecionadas.filter(e => e.id_departamento != idUm || e.id_secao!=idDois);
    }
  }

  filtraAutocomplete(){
    if(this.opcaoCombo=='1'){
      this.produtosAutocomplete = this.produtos.filter(e => e.codigo_produto.indexOf(this.produtoSelecionado.toUpperCase())>-1 || e.descricao.indexOf(this.produtoSelecionado.toUpperCase())>-1 || e.ean.indexOf(this.produtoSelecionado.toUpperCase())>-1 || e.eans.indexOf(this.produtoSelecionado.toUpperCase())>-1);
    }else{
      this.secoes = this.secoesGeral.filter(e => e.descricao.indexOf(this.produtoSelecionado.toUpperCase())>-1 || e.descricao_departamento.indexOf(this.produtoSelecionado.toUpperCase())>-1);
    }
  }

  trocaServico(){
    if(this.opcaoCombo=='1'){
      this.ativaAutoCompleteProdutos = true;
      this.ativaAutoCompleteSecao = false;
      $('#textAutocomplete').attr("placeholder", "Pesquise por Produto, Ean, Codigo do Produto");
    }else{
      this.ativaAutoCompleteSecao = true;
      this.ativaAutoCompleteProdutos = false;
      $('#textAutocomplete').attr("placeholder", "Pesquise por Departamento ou seção");
    }
    
  }

  salvarAtividades(){

    if(this.alertaSelecionado==''){
      alert('Favor preencher o tipo de Atividade!');
      return
    }

    this.ativaLoading = true;

    if(this.opcaoCombo=='1'){
      this.secoesSelecionadas = [];
    }else{
      this.produtosSelecionados = [];
    }
    
    const data = {
      id_alerta: this.alertaSelecionado,
      data_agenda: this.dataAgenda,
      produtos: this.produtosSelecionados,
      secoes: this.secoesSelecionadas
    }

    this.serviceApi.postCriaAtividades(data,this.empresaSelecionado).then(response => {
      this.secoesSelecionadas = [];
      this.produtosSelecionados = [];

      this.produtosAutocomplete = this.produtos;
      this.secoes = this.secoesGeral;

      this.ativaLoading = false;
    });
  }

}
