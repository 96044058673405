<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #C3C3C3; ">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <h3>Cadastro de Empresas</h3>
                <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
            </div>
            <div class="linha-campos">
                <form id="formLojas" action="#" ngNativeValidate autocomplete="off">
                    <div class="row">
                        <!-- <div class="form-group col-md-2">
                            <label for="inputGrupo">Grupo</label>
                            <ng-select [(ngModel)]="id_empresa" name="id_empresa" required>
                                <ng-option *ngFor="let grupo of grupos" [value]="grupo.id">{{grupo.name}}</ng-option>
                            </ng-select>
                        </div> -->
                        <div class="form-group col-md-2">
                            <label for="inputCnpj">CPNJ</label>
                            <input [(ngModel)]="cnpj" name="cnpj" type="text" class="form-control" id="inputCnpj" placeholder="CPNJ" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputRazao">Razao Social</label>
                            <input [(ngModel)]="razao" name="razao" type="text" class="form-control" id="inputRazao" placeholder="Razao Social"  required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputNFantasia">Nome Fantasia</label>
                            <input [(ngModel)]="nome_fantasia" name="nome_fantasia" type="text" class="form-control" id="inputNFantasia" placeholder="Nome Fantasia" required>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputlogradouro">Logradouro</label>
                            <input [(ngModel)]="logradouro" name="logradouro" type="text" class="form-control" id="inputLogradouro" placeholder="Logradouro" required>
                        </div>
                        <div class="form-group col-md-1">
                            <label for="inputNumero">Numero</label>
                            <input [(ngModel)]="numero" name="numero" type="text" class="form-control" id="inputNumero" placeholder="Numero" required>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputBairro">Bairro</label>
                            <input [(ngModel)]="bairro" name="bairro" type="text" class="form-control" id="inputBairro" placeholder="Bairro" required>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputEstado">Estado</label>
                            <select [(ngModel)]="idEstado" name="estado" class="form-select" id="inputEstado" (change)="selecionaEstado()" required>
                                <option *ngFor="let item of estados" [ngValue]="item.id">{{ item.nome.toUpperCase() }}</option>
                            </select>
                        </div>
                        <!-- <div class="form-group col-md-2">
                            <label for="inputMunicipio">Municipio</label>
                            <select [(ngModel)]="idMunicipio" name="municipio" class="form-select" id="inputMunicipio" (change)="selecionaMunicipio()" required>
                                <option *ngFor="let item of municipios" [ngValue]="item.id">{{ item.nome.toUpperCase() }}</option>
                            </select>
                        </div> -->
                        <div class="form-group col-md-1">
                            <label for="inputPais">País</label>
                            <select [(ngModel)]="pais" name="pais" class="form-select" id="inputPais" required>
                                <option *ngFor="let item of paises" [ngValue]="item.valor">{{ item.nome }}</option>
                            </select>
                        </div>
                    </div>
                </form>
                <div class="controle-buttons">
                    <button (click)="limparDados()" class="btn btn-limpar">Limpar</button>
                    <button (click)="inserir()" class="btn btn-inserir">Inserir</button>
                    <button id="buttonSalvar" (click)="salvar()" class="btn btn-salvar">Salvar</button>
                </div>
            </div>
            <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
            <div class="linha-dados">
                <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
                    <thead>
                        <tr>
                        <th style="width: 100px;">CNPJ</th>
                        <th style="width: 100px;">Razao</th>
                        <th style="width: 100px;">Nome Fantasia</th>
                        <th style="width: 75px;">Rua</th>
                        <th style="width: 40px;">Numero</th>
                        <th style="width: 75px;">Bairro</th>
                        <th style="width: 50px;">Municipio</th>
                        <th style="width: 50px;">Estado</th>
                        <th style="width: 40px;">Pais</th>
                        <th style="width: 60px;">Acoes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let loja of lojas">
                        <td>{{ this.formatTextApi.formatCnpjCpf(loja.cnpj) }}</td>
                        <td>{{ loja.razao }}</td>
                        <td>{{ loja.nome_fantasia }}</td>
                        <td>{{ loja.logradouro }}</td>
                        <td>{{ loja.numero }}</td>
                        <td>{{ loja.bairro }}</td>
                        <td>{{ loja.municipio }}</td>
                        <td>{{ loja.estado }}</td>
                        <td>{{ loja.pais }}</td>
                        <td class="acoes-linha">
                            <button type="button" class="btn btn-primary" (click)="editar(loja.id_cliente, loja.id_empresa, loja.id_loja_empresa)"><fa-icon [icon]="faEdit"></fa-icon></button>
                            <button type="button" class="btn btn-danger" (click)="modalPergunta(loja.id_cliente, loja.id_empresa, loja.id_loja_empresa)"><fa-icon [icon]="faTrash"></fa-icon></button>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id=ModalExcluir>
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Excluir registro</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Deseja realmente excluir o registro?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
              <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</div>