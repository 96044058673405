import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter, map } from 'rxjs/operators'

import { TaskwebApiService } from './service/taskweb-api.service';
import { faSearch, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FormatDate } from '../util/format-date';
import Swal from 'sweetalert2';

declare let $: any;

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IDepartamentos = {
  id_departamento: string;
  descricao: string;
}

type ISecoes = {
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type ICategorias = {
  id_categoria: string;
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type ISegmentos = {
  id_segmento: string;
  id_categoria: string;
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type IFornecedores = {
  id_fornecedor: string;
  cnpj: string;
  razao: string;
  fantasia: string;
}

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type IPadrao = {
  id: string;
  descricao: string;
}

type ITables = {
  id_campo: string;
  descricao: string;
  tipo: string;
  tamanhoMaximo: number;
}

type IListaProd = {
  id_alerta: string;
  codigo_prd_interno: string;
  ean: string;
  descricao: string;
  quantidade: number;
  ultima_compra: string;
  preco_ultima_entrada: number;
  ult_preco_compra: string;
  preco_pedido: number;
  total: number;
  ult_venda: string;
  unidade: string;
  estoque_atual: number;
  dias_estoque: number;
  dias_estoque_sug: number;
  qtd_media_venda_mes: number;
  qtd_pedido:number;
  acao: string;
}

@Component({
  selector: 'app-taskweb',
  templateUrl: './taskweb.component.html',
  styleUrls: ['./taskweb.component.css']
})
export class TaskwebComponent implements OnInit, AfterViewInit {

  faSearch = faSearch;
  faAngleDoubleRight = faAngleDoubleRight;

  Toast: any = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  ativaLoading: boolean = true;

  limite: number = 5;
  size: number = 100;
  isOpen: boolean = false;
  searchTerm: string;

  empresasGeral: IEmpresas[] = [];
  empresaSelecionado: string = '';
  departamentos: IDepartamentos[] = [];
  departamentoSelecionado: string = '';
  secoesGeral: ISecoes[] = [];
  secoes: ISecoes[] = [];
  secaoSelecionado: string = '';
  fornecedores: IFornecedores[] = [];
  fornecedoresBuffer: IFornecedores[] = [];
  fornecedoresGeral: IFornecedores[] = [];
  fornecedorSelecionado: string = '';

  filtroSemEstoqueSel: string = '';
  filtroSemEstoque: IPadrao[] = [{id: 'SIM', descricao: 'Com Estoque'},{id: 'NAO', descricao: 'Sem Estoque'}];
  filtroItensComPedidoSel: string = '';
  filtroItensComPedido: IPadrao[] = [{id: 'SIM', descricao: 'Com Pedido'},{id: 'NAO', descricao: 'Sem Pedido'}];
  filtroComAtividadesSel: string = '';
  filtroComAtividades: IPadrao[] = [{id: 'SIM', descricao: 'Com Atividades'},{id: 'NAO', descricao: 'Sem Atividade'}];
  filtroDiasEstoque: number = 45;

  acaoEscolhida: IPadrao[] = [{id: '1', descricao: 'Fazer Pedido'},{id: '2', descricao: 'Bloquear Produto'},{id: '3', descricao: 'Lista de Inventario'},{id: '4', descricao: 'Calcular Preço'}];

  listaProdutos: IListaProd[] = [];
  listaProdutosTemp: IListaProd[] = [];

  photos = [];
  photosBuffer = [];
  bufferSize = 50;
  loading = false;
  input$ = new Subject<string>();
  termoEscrito = '';

  colTable: ITables[] = [/*{id_campo: 'id_alerta', descricao:'Alerta'},*/
                         {id_campo: 'codigo_prd_interno', descricao:'Código do produto', tipo:'text', tamanhoMaximo: 5},
                         {id_campo: 'ean', descricao:'EAN do Produto', tipo:'text', tamanhoMaximo: 5},
                         {id_campo: 'descricao_produto', descricao:'Descrição do Produto', tipo:'text', tamanhoMaximo: 15},
                         {id_campo: 'maior_data_entrada_documento', descricao:'Ultima compra', tipo:'date', tamanhoMaximo: 5},
                         {id_campo: 'preco_ultima_entrada', descricao:'Ultimo preço de compra', tipo:'valor', tamanhoMaximo: 6},
                         {id_campo: 'preco_pedido', descricao:'Preço de Pedido (R$)', tipo:'input', tamanhoMaximo: 6},
                         {id_campo: 'ultima_venda', descricao:'Ultima venda', tipo:'date', tamanhoMaximo: 5},
                         {id_campo: 'qtd_media_venda_mes', descricao:'Media venda', tipo:'number', tamanhoMaximo: 5},
                         {id_campo: 'qtd_pedido', descricao:'Quantidade Pedido', tipo:'number', tamanhoMaximo: 5},
                         {id_campo: 'total', descricao:'Total Previsto', tipo:'number', tamanhoMaximo: 5},
                         {id_campo: 'estoque_atual', descricao:'Estoque Atual', tipo:'number', tamanhoMaximo: 5},
                         {id_campo: 'dias_estoque', descricao:'Dias de estoque', tipo:'input', tamanhoMaximo: 5},
                         {id_campo: 'dia_estoque_sugerido', descricao:'Dias de estoque sugerido', tipo:'number', tamanhoMaximo: 5},
                         {id_campo: 'unidade_compra', descricao:'Unidade da sugestão', tipo:'text', tamanhoMaximo: 5},
                         {id_campo: 'acao', descricao:'Ação escolhida', tipo:'button', tamanhoMaximo: 10}]
  tableData = [];

  constructor(
    private apiTaskWeb: TaskwebApiService,
    private formatDate: FormatDate,
  ) { }
  
  ngOnInit(): void {

    this.ativaLoading = true;

    var dataAtuais = sessionStorage.getItem('datasPesq');
    if(dataAtuais){
      this.datasAtuais = JSON.parse(dataAtuais);
      this.dataInicial = this.datasAtuais.dataDe;
      this.dataFinal = this.datasAtuais.dataAte;
    }else{
      this.dataInicial = this.formatDate.formatDateForInput(new Date(), 1);
      this.dataFinal = this.formatDate.formatDateForInput(new Date(), 0);

      this.datasAtuais = {
        dataDe: this.dataInicial,
        dataAte: this.dataFinal
      }
  
      sessionStorage.setItem('datasPesq', JSON.stringify(this.datasAtuais));
    }

    var empresaAtual = sessionStorage.getItem('etl@logEmpresa');
    if(empresaAtual){
      this.empresaSelecionado = empresaAtual;
    }

    this.carregaFiltros();
    this.onSearch();
    this.carregaDados();
    
    
  }

  async carregaFiltros(){
    await this.apiTaskWeb.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.empresasGeral = result.empresas;

      if(this.empresaSelecionado==''){
        this.empresaSelecionado = this.empresasGeral[0].id_cliente+'_'+this.empresasGeral[0].id_empresa+'_'+this.empresasGeral[0].id_loja_empresa;
      }
      
    })

    await this.apiTaskWeb.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.departamentos = result.departamentos;
      this.secoesGeral = result.secoes;
      this.fornecedores = result.fornecedores;
      this.fornecedoresBuffer = this.fornecedores.slice(0, this.bufferSize);
    })
  }

  carregaDados(){
    this.ativaLoading = true;

    if(this.fornecedorSelecionado){
      this.apiTaskWeb.listaTaskWeb(this.empresaSelecionado,this.fornecedorSelecionado, this.departamentoSelecionado, this.secaoSelecionado, this.filtroSemEstoqueSel, this.filtroComAtividadesSel, this.filtroItensComPedidoSel).then(result => {
        this.listaProdutos = result.listaTask;
        this.listaProdutos.map(e => {
          e.qtd_pedido = Number(((e.qtd_media_venda_mes * e.dias_estoque) - e.estoque_atual).toFixed(0));
          if(e.qtd_pedido < 1){
            e.qtd_pedido = 0;
          }
          e.preco_pedido = Number(e.preco_ultima_entrada.toFixed(2));
          e.total = Number((e.qtd_pedido*e.preco_pedido).toFixed(2));
        });
        this.listaProdutosTemp = this.listaProdutos.slice(0, this.size);
        this.ativaLoading = false;
      });
    }else{
      this.Toast.fire({
        icon: 'warning',
        title: 'Selecione um fornecedor!'
      })
      this.ativaLoading = false;
    }
  }

  fetchMore(term) {
    const len = this.fornecedoresBuffer.length;
    const more = this.fornecedores.filter(x => x.razao.includes(this.termoEscrito.toUpperCase()) || x.cnpj.includes(this.termoEscrito.toUpperCase())).slice(len, this.bufferSize + len);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.fornecedoresBuffer = this.fornecedoresBuffer.concat(more);
    }, 200);
  }

  atualizaPesquisa(){
    this.carregaDados();
  }

  buscaOpcoes(opcao:string){
    if(opcao=='DEPARTAMENTO'){
      this.secoes = this.secoesGeral.filter(e => e.id_departamento == this.departamentoSelecionado);
    }

    if(this.departamentoSelecionado==''){
      this.secaoSelecionado = '';
      this.secoes = [];
    }
  }

  filtraListaLogs(){
    // this.ativaLoading = true;
    // this.logAtividades = this.logAtividadesGeral;

    // if (this.departamentoSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.id_departamento == this.departamentoSelecionado);
    // }
    // if (this.secaoSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.id_secao == this.secaoSelecionado);
    // }
    // if (this.categoriaSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.id_categoria == this.categoriaSelecionado);
    // }
    // if (this.segmentoSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.id_segmento == this.segmentoSelecionado);
    // }
    // if (this.operadorSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.id_usuario == this.operadorSelecionado);
    // }
    // if (this.statusSelecionado!=''){
    //   this.logAtividades = this.logAtividades.filter(e => e.status == this.statusSelecionado);
    // }

    // this.buscaDadosTotais(false);
    
  }

  // customSearchFn(term: string, item) {
  //   const more = this.fornecedores.filter(e => e.razao.includes(term));
  //   term = term.toLocaleLowerCase();
  //   this.fornecedoresBuffer = more;
  //   return more.length > 0;
  // }
  onSearch() {
    this.input$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.fakeService(term))
    )
      .subscribe(data => {
        if(this.fornecedoresBuffer.length <= this.bufferSize){
          this.fornecedoresBuffer.push(data);
        }
      })
  }

  private fakeService(term) {
    this.fornecedoresBuffer = [];
    if(term){
      this.termoEscrito = term;
      return this.fornecedores.filter(x => x.razao.includes(term.toUpperCase()) || x.cnpj.includes(term.toUpperCase()));
    }else{
      this.termoEscrito = '';
      return this.fornecedores;
    }
  }

  GatilhoCampo(id: string, codigo: string){
    if(id=='dias_estoque'){
      this.listaProdutos.filter(e => {
        if(e.codigo_prd_interno==codigo){
          e.qtd_pedido = Number(((e.qtd_media_venda_mes * e.dias_estoque) - e.estoque_atual).toFixed(0));
          e.preco_pedido = Number(e.preco_ultima_entrada.toFixed(2));
          e.total = Number((e.qtd_pedido*e.preco_pedido).toFixed(2));
        }
      });

      this.listaProdutosTemp.filter(e => {
        if(e.codigo_prd_interno==codigo){
          e.qtd_pedido = Number(((e.qtd_media_venda_mes * e.dias_estoque) - e.estoque_atual).toFixed(0));
          e.preco_pedido = Number(e.preco_ultima_entrada.toFixed(2));
          e.total = Number((e.qtd_pedido*e.preco_pedido).toFixed(2));
        }
      })
    }
  }

  carregaMais(){
    this.size += 100;
    this.listaProdutosTemp = this.listaProdutos.slice(0, this.size);
  }


  loadMasks(){
    $(".dinheiro").keydown(function(){
      try {
        $(".dinheiro").unmask();
      } catch (e) {}
  
      $(".dinheiro").mask('#.##0,00', {reverse: true});
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(function(){
      $(".dinheiro").mask('000.000.000.000.000,00', {reverse: true});

      $('.dinheiro').blur(function() {
        if ($(this).val().length === 0) {
          $(this).val('0,00');
        }
      });
  }, 2000);
  }
}
