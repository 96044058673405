import { Injectable } from '@angular/core';

import { User } from './../../models/user.model';
import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Encryption } from 'src/app/util/encryption';

@Injectable({
  providedIn: 'root'
})
export class TaskwebApiService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  listaOpcoes(empresaSelect: any){

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: this.infoUser.id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_info_geral_filtros',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  listaTaskWeb(empresaSelect: any, fornecedor: string, idDepartamento: string, idSecao: string, CSEstoque: string, CSAtividades: string, CSPedidos: string){

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    var data = {
      "id_empresa": idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      "id_filial_empresa": idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      "fornecedor": fornecedor,
      "departamento": idDepartamento,
      "secao": idSecao,
      "c_s_estoque": CSEstoque,
      "c_s_atividades": CSAtividades,
      "c_s_pedidos": CSPedidos
    }

    return this.configApi.configServEtl.post<string, any>('get_lista_taskweb_portal',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }
}
