import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { CadastroApiService } from './../service/cadastro-api.service';
import { IContatos } from 'src/app/models/IContatos.model';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormatText } from './../../util/formt-text';

declare let $: any;

@Component({
  selector: 'app-contatos',
  templateUrl: './contatos.component.html',
  styleUrls: ['./contatos.component.css']
})
export class ContatosComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  contatos: IContatos[] = [];
  contatoAtual: IContatos;
  dtTrigger: Subject<any> = new Subject<any>();

  id_contato: number = null;
  id_loja: string;
  nome:string;
  setor:string;
  cpf:string;
  telefone:string;
  tipoAtual: string;

  faEdit = faEdit;
  faTrash = faTrash;

  lojas = [];

  constructor(
    private serviceApi: CadastroApiService,
    private formatTextApi: FormatText
  ) { }

  ngOnInit(): void {
    this.tipoAtual = 'I';

    this.configDataTable();
    this.buscaDados();
    this.loadMasks();
  }

  buscaDados(){
    this.serviceApi.getDadosContatos('get_contacts_portal', this.id_loja).then(response => {
      this.contatos = response.contatos;
      this.rerender();
    });

    this.lojas = [];
    this.serviceApi.getDados('get_companies_portal').then(response => {
      response.empresas.map(res => {
        this.lojas.push({id: res['id_loja'], name: res['razao']});
      })
    });
  }

  async salvar(){

    if(this.id_loja && this.nome && this.setor && this.cpf && this.telefone){
      this.contatoAtual = { 
        id_contato: this.id_contato,
        id_loja: this.id_loja,
        nome: this.nome,
        cpf: this.cpf.replace(/[\/\*\|\.\<\>\?\"-]/gi, ''),
        telefone: this.telefone,
        setor: this.setor
      }
  
      await this.serviceApi.postDadosContatos(this.contatoAtual, this.tipoAtual).then(res =>{
        if(res['success']){
          this.buscaDados();
          this.limparDados();
        }
      });
    }

  }

  editar(idContato){
    var contatoSelecionado = this.contatos.filter(e => e.id_contato == idContato)[0];
    this.tipoAtual = 'E';
    this.id_contato = contatoSelecionado.id_contato;
    this.id_loja = contatoSelecionado.id_loja;
    this.nome = contatoSelecionado.nome;
    this.cpf = contatoSelecionado.cpf;
    this.setor = contatoSelecionado.setor;
    this.telefone = contatoSelecionado.telefone;
  }

  modalPergunta(idContato){
    var contatoSelecionado = this.contatos.filter(e => e.id_contato == idContato )[0];
    this.tipoAtual = 'D';
    this.id_contato = contatoSelecionado.id_contato;
    this.id_loja = contatoSelecionado.id_loja;
    this.nome = contatoSelecionado.nome;
    this.cpf = contatoSelecionado.cpf;
    this.setor = contatoSelecionado.setor;
    this.telefone = contatoSelecionado.telefone;

    $('#ModalExcluir').modal('show');
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.id_contato = null;
      this.id_loja = null;
      this.nome = '';
      this.cpf = '';
      this.setor = '';
      this.telefone = '';

      this.tipoAtual = 'I';
    }
    $('#ModalExcluir').modal('toggle');
  }

  limparDados(){
    this.id_contato = null;
    this.id_loja = null;
    this.nome = '';
    this.cpf = '';
    this.setor = '';
    this.telefone = '';

    this.tipoAtual = 'I';
  }

  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 3,
      scrollY: "350px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadMasks(){
    $("#inputCpf").keydown(function(){
      try {
        $("#inputCpf").unmask();
      } catch (e) {}
  
      $("#inputCpf").mask("999.999.999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#inputCnpj").keydown(function(){
      try {
          $("#inputCnpj").unmask();
      } catch (e) {}
  
      $("#inputCnpj").mask("99.999.999/9999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#inputTelefone").keydown(function(){
      try {
          $("#inputTelefone").unmask();
      } catch (e) {}
  
      $("#inputTelefone").mask("(99)9999-999999");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });
  }

}
