<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #959fb1; ">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-9 titulo-painel">
                        <h3>TASKWEB</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDataInical">Empresa:</label>
                        <select [(ngModel)]="empresaSelecionado" id="input_empresa" class="form-control" aria-label="Default select example">
                            <option *ngIf="empresasGeral.length>1"></option>
                            <option *ngFor="let itemEmpresa of empresasGeral" value="{{itemEmpresa.id_cliente+'_'+itemEmpresa.id_empresa+'_'+itemEmpresa.id_loja_empresa}}">{{itemEmpresa.descricao}}</option>
                        </select>
                    </div>
                    <div class="col-md-1 button-pesquisa">
                        <button class="btn btn-success" type="submit" (click)="atualizaPesquisa()">Atualizar</button>
                    </div>
                </div>
                <hr>
            </div>
            <div class="grupo-filtros">
                <div class="row">
                    <!-- <div class="form-group col-md-2 periodo" >
                        <label for="inputFornecedor">Fornecedor:</label>
                        <ng-select [(ngModel)]="fornecedorSelecionado" (change)="buscaOpcoes('FORNECEDOR');filtraListaLogs()" (scrollToEnd)="onScroll()">
                            <ng-option *ngFor="let itemFornecedor of fornecedores" [value]="itemFornecedor.id_fornecedor">{{itemFornecedor.cnpj}} - {{itemFornecedor.razao}}</ng-option>
                         </ng-select>
                    </div> -->
                    <!-- <div class="form-group col-md-2 periodo" >
                        <label for="inputFornecedor">Fornecedor:</label>
                        <div >
                        <input type="text" list="options" name="listCropBeds">
                        <datalist id="options" class="dropdown" #datalist>
                            <option *ngFor="let itemFornecedor of fornecedores" [value]="itemFornecedor.id_fornecedor">{{itemFornecedor.cnpj}} - {{itemFornecedor.razao}}</option>
                          </datalist>
                        </div>
                    </div> -->
                    <div class="form-group col-md-3 periodo">
                        <label for="inputFornecedor">Fornecedor:</label>
                        <ng-select 
                            [items]="fornecedoresBuffer" 
                            [(ngModel)]="fornecedorSelecionado" 
                            [loading]="loading" 
                            bindLabel="razao"
                            bindValue="cnpj"
                            [typeahead]="input$"
                            (scrollToEnd)="fetchMore(select.filterValue)" 
                            (change)="carregaDados()"
                            #select>
                            <!-- <ng-option *ngFor="let itemFornecedor of fornecedoresBuffer" [value]="itemFornecedor.id_fornecedor">{{itemFornecedor.cnpj}} - {{itemFornecedor.razao}}</ng-option> -->
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{item.cnpj}} - {{item.razao}}
                            </ng-template>
                         </ng-select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDepartamento">Departamento:</label>
                        <select [(ngModel)]="departamentoSelecionado" id="input_departamento" class="form-control" aria-label="Default select example" (change)="buscaOpcoes('DEPARTAMENTO');filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemDepartamento of departamentos" value="{{itemDepartamento.id_departamento}}">{{itemDepartamento.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputSecao">Seção:</label>
                        <select [(ngModel)]="secaoSelecionado" [disabled]="departamentoSelecionado!=''?false:true" id="input_secao" class="form-control" aria-label="Default select example" (change)="buscaOpcoes('SECAO');filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemSecao of secoes" value="{{itemSecao.id_secao}}">{{itemSecao.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="input_semestoque">Estoque:</label>
                        <select [(ngModel)]="filtroSemEstoqueSel" id="input_semestoque" class="form-control" aria-label="Default select example">
                            <option></option>
                            <option *ngFor="let itemSemEstoque of filtroSemEstoque" value="{{itemSemEstoque.id}}">{{itemSemEstoque.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="input_comatividades">Atividades Lojas:</label>
                        <select [(ngModel)]="filtroComAtividadesSel" id="input_comatividades" class="form-control" aria-label="Default select example">
                            <option></option>
                            <option *ngFor="let itemComAtividade of filtroComAtividades" value="{{itemComAtividade.id}}">{{itemComAtividade.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="input_itenscompedido">Pedido:</label>
                        <select [(ngModel)]="filtroItensComPedidoSel" id="input_itenscompedido" class="form-control" aria-label="Default select example">
                            <option></option>
                            <option *ngFor="let itemComPedido of filtroItensComPedido" value="{{itemComPedido.id}}">{{itemComPedido.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="input_diasestoque">Dias de estoque:</label>
                        <input [(ngModel)]="filtroDiasEstoque" type="number" class="form-control" id="input_diasestoque">
                    </div>
                </div>
            </div>
            <div class="tabela-dados">
                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                        <th *ngFor="let column of colTable" [ngStyle]="{'width':column.tamanhoMaximo+'%'}">{{ column.descricao }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaProdutosTemp">
                            <td *ngFor="let data of colTable" [ngStyle]="{'width':data.tamanhoMaximo+'%','vertical-align': 'middle', 'font-weight': '600'}">
                                <div *ngIf="data.tipo=='text' && item[data.id_campo]">
                                    {{item[data.id_campo]}}
                                </div>
                                <div *ngIf="data.tipo=='date'" style="text-align: center;">
                                    {{this.formatDate.formtDateText(item[data.id_campo])}}
                                </div>
                                <div *ngIf="data.tipo=='valor'" style="text-align: right;">
                                    {{item[data.id_campo].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                </div>
                                <div *ngIf="data.tipo=='number'">
                                    {{item[data.id_campo]}}
                                </div>
                                <div *ngIf="!item[data.id_campo] && !data.tipo=='date'">
                                    {{item[data.id_campo]}}
                                </div>
                                <div *ngIf="data.tipo=='input'"> 
                                    <!-- <input [(ngModel)]="item[data.id_campo]" type="number" class="form-control"  id="input_precoPedido"> -->
                                    <input [(ngModel)]="item[data.id_campo]" (change)="GatilhoCampo(data.id_campo,item.codigo_prd_interno)" id="{{data.id_campo+'_'+item.codigo_prd_interno}}"  type="text" class="form-control" style="display:inline-block;text-align: right;"/>
                                </div>
                                <div *ngIf="data.tipo=='button'"  class="form-group periodo">
                                    <select [(ngModel)]="item.acao" 
                                            id="input_acao" 
                                            class="form-control"
                                            aria-label="Default select example">
                                        <option *ngFor="let itemAcao of acaoEscolhida" value="{{itemAcao.id}}">{{itemAcao.descricao}}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-success" *ngIf="listaProdutos.length!=listaProdutosTemp.length" (click)="carregaMais()">
                    Carregar Mais
                </button>
            </div>
        </div>
    </div>
</div>