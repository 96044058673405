<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #24223e;">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-9 titulo-painel">
                        <h3>Terus Teams</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="form-group col-md-3 periodo">
                        <label for="inputDataInical">Cadastro Atividades Manuais:</label>
                        <select [(ngModel)]="empresaSelecionado" id="input_empresa" class="form-control" aria-label="Default select example">
                            <option *ngIf="empresasGeral.length>1"></option>
                            <option *ngFor="let itemEmpresa of empresasGeral" value="{{itemEmpresa.id_cliente+'_'+itemEmpresa.id_empresa+'_'+itemEmpresa.id_loja_empresa}}">{{itemEmpresa.descricao}}</option>
                        </select>
                    </div>
                </div>
                <hr>
            </div>
            <div class="linha-campos">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <select [(ngModel)]="opcaoCombo" id="input_combo" class="form-control" aria-label="Default select example" (change)="trocaServico()">
                            <option *ngFor="let itensOpcoes of opcoesCombo" value="{{itensOpcoes.id}}">{{itensOpcoes.descricao}}</option>
                        </select>
                        <input [(ngModel)]="produtoSelecionado" id="textAutocomplete" type="text" class="form-control" name="textAutocomplete" style="text-transform:uppercase"
                                placeholder="" autocomplete="off" (keyup)="filtraAutocomplete()">
                        <div *ngIf="ativaAutoCompleteProdutos" class="espaco-autocomplete">
                            <div *ngFor="let item of produtosAutocomplete" class="dados-produtos">
                                <span>{{item.descricao}}</span>
                                <button class="btn btn-salvar" (click)="adiciona('produto',item.codigo_produto,'')">Adicionar</button>
                            </div>
                        </div>
                        <div *ngIf="ativaAutoCompleteSecao" class="espaco-autocomplete">
                            <div *ngFor="let itemSecao of secoes" class="dados-produtos">
                                <span>{{(itemSecao.descricao_departamento)}} - {{itemSecao.descricao}}</span>
                                <button class="btn btn-salvar" (click)="adiciona('secao',itemSecao.id_departamento,itemSecao.id_secao)">Adicionar</button>
                            </div>
                        </div>
                        
                        <!-- <input [(ngModel)]="descricao" name="descricao" type="text" class="form-control" id="inputDescricao" placeholder="Descricao" style="text-transform:uppercase" required> -->
                        <!-- <select [(ngModel)]="produtoSelecionado" id="input_status" class="form-control" aria-label="Default select example">
                            <option></option>
                            <option *ngFor="let itemStatus of produ" value="{{itemStatus.id}}">{{itemStatus.descricao}}</option>
                        </select> -->
                    </div>
                    <div class="form-group col-md-6" style="display: flex;">
                        <div class="row" style="width: 100%;margin: 0;">
                            <div class="form-group col-md-6 periodo">
                                <label for="dataAgenda">Data Agenda:</label>
                                <input [(ngModel)]="dataAgenda" name="dataAgenda" type="date" class="form-control" id="dataAgenda">
                            </div>
                            <div class="form-group col-md-6 periodo">
                                <label for="inputTipoAtividade">Tipo Atividade:</label>
                                <select [(ngModel)]="alertaSelecionado" id="inputTipoAtividade" class="form-control" aria-label="Default select example">
                                    <option *ngIf="alertas.length>1"></option>
                                    <option *ngFor="let itemAtividade of alertas" value="{{itemAtividade.id_alerta}}">{{itemAtividade.descricao}}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-12 periodo" style="padding: 0;">
                                <div *ngIf="ativaAutoCompleteProdutos" class="espaco-autocomplete">
                                    <div *ngFor="let item of produtosSelecionados" class="dados-produtos">
                                        <span>{{item.descricao}}</span>
                                        <button class="btn btn-danger" (click)="remove('produto',item.codigo_produto,'')">Remover</button>
                                    </div>
                                </div>
                                <div *ngIf="ativaAutoCompleteSecao" class="espaco-autocomplete">
                                    <div *ngFor="let item of secoesSelecionadas" class="dados-produtos">
                                        <span>{{(item.descricao_departamento)}} - {{item.descricao}}</span>
                                        <button class="btn btn-danger" (click)="remove('secao',item.id_departamento,item.id_secao)">Remover</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="linha-buttons">
                <div class="controle-buttons">
                    <button id="buttonSalvar"  class="btn btn-salvar" (click)="salvarAtividades()">Salvar</button>
                </div>
            </div>
            <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
        </div>
    </div>
  </div>