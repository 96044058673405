import { Component, OnInit } from '@angular/core';

import { HeaderComponent } from './../header/header.component';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    HeaderComponent.emitirTrocaDistribuidora.subscribe(tipo => {
      if(tipo==='open'){
        $('.embedded').addClass('open');
      }else{
        $('.embedded').removeClass('open');
      }
    })
  }

}
