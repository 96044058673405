<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #C3C3C3; ">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-12 titulo-painel">
                        <h3>Lista Pedidos</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-2 periodo">
                        <label for="inputEmpresa">Empresa:</label>
                        <select [(ngModel)]="empresaSelecionado" id="input_empresa" class="form-control" aria-label="Default select example" >
                            <option *ngIf="empresasGeral.length>1"></option>
                            <option *ngFor="let itemEmpresa of empresasGeral" value="{{itemEmpresa.id_cliente+'_'+itemEmpresa.id_empresa+'_'+itemEmpresa.id_loja_empresa}}">{{itemEmpresa.id_loja_empresa +' - '+ itemEmpresa.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputFornecedor">Fornecedor:</label>
                        <ng-select 
                            [items]="fornecedoresBuffer" 
                            [(ngModel)]="fornecedorSelecionado" 
                            [loading]="loading" 
                            bindLabel="razao"
                            bindValue="cnpj"
                            [typeahead]="input$"
                            (scrollToEnd)="fetchMore(select.filterValue)" 
                            #select>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{item.cnpj}} - {{item.razao}}
                            </ng-template>
                         </ng-select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="inputStatus">Status:</label>
                        <select [(ngModel)]="statusSelecionado" id="input_empresa" class="form-control" aria-label="Default select example">
                            <option *ngFor="let statusItem of listaStatus" value="{{statusItem.id}}">{{statusItem.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 periodo">
                        <label for="inputStatus">Origem:</label>
                        <select [(ngModel)]="origemSelecionado" id="input_tipo" class="form-control" aria-label="Default select example" (change)="filtraDados('origem')">
                            <option *ngFor="let oriItem of listaOrigem" value="{{oriItem.id}}">{{oriItem.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDataInical">Data início:</label>
                        <input [(ngModel)]="dataInicial" name="dataInicial" type="date" class="form-control" id="inputDataInical">
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputFim">Data fim:</label>
                        <input [(ngModel)]="dataFinal" name="dataFinal" type="date" class="form-control" id="inputDataFinal">
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputPesquisa">Pesquisa:</label>
                        <input [(ngModel)]="pesquisa" name="pesquisa" type="text" class="form-control" id="inputPesquisa" (keyup)="filtraDados('')">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 button-pesquisa">
                        <button class="btn btn-success bg-gradient" type="submit" (click)="atualizaPesquisa()">
                            <fa-icon [icon]="faRotateRight"></fa-icon>
                        </button>
                        <button class="btn btn-primary bg-gradient" type="submit" (click)="visualizaPedido()">Visualizar</button>
                        <button class="btn btn-secondary bg-gradient" type="submit" (click)="aprovaPedido()">Aprovar</button>
                        <button class="btn btn-secondary bg-gradient" type="submit" (click)="reprovaPedido()">Reprovar</button>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="tabela-dados table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th (click)="ordernar('status_desc')">
                                STATUS
                                <span *ngIf="key=='status_desc'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('tipo_carga')">
                                TIPO CARGA
                                <span *ngIf="key=='tipo_carga'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('id_vendedor')">
                                ID VENDEDOR
                                <span *ngIf="key=='id_vendedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('nome_vendedor')">
                                NOME VENDEDOR
                                <span *ngIf="key=='nome_vendedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 100px;" (click)="ordernar('data_pedido_sem_formato')">
                                DATA
                                <span *ngIf="key=='data_pedido_sem_formato'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 100px;" (click)="ordernar('data_confirma')">
                                DATA CONFIRMADO
                                <span *ngIf="key=='data_confirma'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 150px;" (click)="ordernar('cnpj_fornecedor')">
                                FORNECEDOR
                                <span *ngIf="key=='cnpj_fornecedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 200px;" (click)="ordernar('razao_social_fornecedor')">
                                NOME FORNECEDOR
                                <span *ngIf="key=='razao_social_fornecedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 200px;" (click)="ordernar('id_pedido')">
                                CÓD. PEDIDO
                                <span *ngIf="key=='id_pedido'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 200px;" (click)="ordernar('numero_pedido_origem')">
                                CÓD. ORIGEM
                                <span *ngIf="key=='numero_pedido_origem'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaPedidos | orderBy: order : key" 
                        (click)="selecionaLinha(item.id_pedido)" 
                        (dblclick)="cardDoubleClick(item.id_pedido)"
                        id="{{item.id_pedido}}" 
                        [ngClass]="item.id_pedido==idSelecionado? 'linhaSelecionada' : ''">
                            <td>{{item.status_desc}}</td>
                            <td>{{item.tipo_carga}}</td>
                            <td>{{item.id_vendedor}}</td>
                            <td>{{item.nome_vendedor}}</td>
                            <td style="text-align: center">{{item.data_pedido}}</td>
                            <td style="text-align: center">{{item.data_confirma}}</td>
                            <td>{{item.cnpj_fornecedor}}</td>
                            <td>{{item.razao_social_fornecedor}}</td>
                            <td>{{item.id_pedido}}</td>
                            <td>{{item.numero_pedido_origem}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="tabela-dados table-responsive">
                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th>Cód. Pedido</th>
                            <th>status</th>
                            <th>Data</th>
                            <th>Data Confirmado</th>
                            <th>Fornecedor</th>
                            <th>Nome Fornecedor</th>
                            <th>Aprova / Reprova</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of listaPedidos">
                        <tr (click)="escondeInfo('linha'+item.id_pedido)">
                            <td>{{item.id_pedido}}</td>
                            <td>{{item.status_pedido}}</td>
                            <td>{{item.data_pedido}}</td>
                            <td>{{item.data_confirma}}</td>
                            <td>{{item.cnpj_fornecedor}}</td>
                            <td>{{item.razao_social_fornecedor}}</td>
                            <td></td>
                        </tr>

                        <tr id="linha{{item.id_pedido}}" class="esconde">
                            <td colspan="7">
                                <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Cód. no fornecedor</th>
                                            <th>Cód. produto</th>
                                            <th>Ean</th>
                                            <th>Descriação prod.</th>
                                            <th>Ult. saída</th>
                                            <th>Ult. entrada</th>
                                            <th>Ciclo venda com estoque</th>
                                            <th>Preço de venda</th>
                                            <th>Venda média diária</th>
                                            <th>Quant. Pedido</th>
                                            <th>Dias de estoque</th>
                                            <th>Dias estoque pós Pedido</th>
                                            <th>Quant. Multiplo</th>
                                            <th>Total</th>
                                            <th>Total ajuste multiplo</th>
                                            <th>Capacidade</th>
                                            <th>Estoque</th>
                                            <th>Multiplo Fornecedor</th>
                                            <th>Fator Ajuste</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let itemPedidos of item.itens">
                                        <tr>
                                            <td>{{itemPedidos.id_codigo_produto_fornecedor}}</td>
                                            <td>{{itemPedidos.codigo_produto_cliente}}</td>
                                            <td>{{itemPedidos.ean}}</td>
                                            <td>{{itemPedidos.descricao_produto}}</td>
                                            <td>{{itemPedidos.ultima_saida}}</td>
                                            <td>{{itemPedidos.ultima_entrada}}</td>
                                            <td>{{itemPedidos.ciclo_venda_com_estoque}}</td>
                                            <td>{{itemPedidos.preco_venda.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                                            <td>{{itemPedidos.venda_med_diaria.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_pedido}}</td>
                                            <td>{{itemPedidos.dias_estoq_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.dias_estoq_pos_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.quant_ajst_multiplo_confirmada?itemPedidos.quant_ajst_multiplo_confirmada:itemPedidos.quant_ajst_multiplo}}</td>
                                            <td>{{itemPedidos.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                                            <td>{{itemPedidos.total_ajst_mtp_conf}}</td>
                                            <td>{{itemPedidos.capacidade_confirmada?itemPedidos.capacidade_confirmada:itemPedidos.capacidade}}</td>
                                            <td>{{itemPedidos.estoque_confirmado?itemPedidos.estoque_confirmado:itemPedidos.saldo_estoque}}</td>
                                            <td>{{itemPedidos.multiplo_fornecedor}}</td>
                                            <td>{{itemPedidos.fator_ajuste}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            
            <!-- <p-table [value]="listaPedidos" 
                dataKey="id_pedido" 
                [tableStyle]="{ 'min-width': '60rem' }" 
                [scrollable]="true" scrollHeight="500px"
                class="table table-striped">
                <ng-template pTemplate="header">
                    <tr style="background-color: #6E7D81;">
                        <th>Cód. Pedido</th>
                        <th>status</th>
                        <th>Data</th>
                        <th>Data Confirmado</th>
                        <th>Fornecedor</th>
                        <th>Nome Fornecedor</th>
                        <th>Aprova / Reprova</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pedido>
                    <tr>
                        <td>{{pedido.id_pedido}}</td>
                        <td>{{pedido.status_pedido}}</td>
                        <td>{{pedido.data_pedido}}</td>
                        <td>{{pedido.data_confirma}}</td>
                        <td>{{pedido.cnpj_fornecedor}}</td>
                        <td>{{pedido.razao_social_fornecedor}}</td>
                        <td>
                            <div class="">
                                
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table> -->
        </div>
    </div>
</div>


