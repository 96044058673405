import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ITurnos } from './../../models/ITurnos.model';
import { CadastroApiService } from './../service/cadastro-api.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.css']
})
export class TurnosComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  faEdit = faEdit;
  faTrash = faTrash;

  turnos: ITurnos[] = [];
  turnoAtual: ITurnos;

  id_turno: string = null;
  descricao:string;
  inicio_hora:string;
  inicio_hora_intervalo:string;
  fim_hora_intervalo:string;
  fim_hora:string;
  tipoAtual: string;

  constructor(
    private serviceApi: CadastroApiService,
  ) { }

  ngOnInit(): void {
    this.tipoAtual = 'I';

    this.configDataTable();
    this.buscaDados();
  }

  buscaDados(){
    this.serviceApi.getDados('get_turn_operator_portal').then(response => {
      this.turnos = response.turnos;
      this.rerender();
    });
  }

  async salvar(){

    if(this.descricao){
      this.turnoAtual = { 
        id_turno: this.id_turno,
        descricao: this.descricao,
        inicio_hora: this.inicio_hora,
        inicio_hora_intervalo: this.inicio_hora_intervalo,
        fim_hora_intervalo: this.fim_hora_intervalo,
        fim_hora: this.fim_hora
      }
  
      await this.serviceApi.postDadosTurnos(this.turnoAtual, this.tipoAtual).then(res =>{
        if(res['success']){
          this.buscaDados();
          this.limparDados();
        }
      });
    }

  }

  editar(idTurno){
    var turnoSelecionado = this.turnos.filter(e => e.id_turno == idTurno )[0];
    this.tipoAtual = 'E';
    this.id_turno = turnoSelecionado.id_turno;
    this.descricao = turnoSelecionado.descricao;
    this.inicio_hora = turnoSelecionado.inicio_hora;
    this.inicio_hora_intervalo = turnoSelecionado.inicio_hora_intervalo;
    this.fim_hora_intervalo = turnoSelecionado.fim_hora_intervalo;
    this.fim_hora = turnoSelecionado.fim_hora;
  }

  modalPergunta(idTurno){
    var turnoSelecionado = this.turnos.filter(e =>e.id_turno == idTurno )[0];
    this.tipoAtual = 'D';
    this.id_turno = turnoSelecionado.id_turno;
    this.descricao = turnoSelecionado.descricao;

    $('#ModalExcluir').modal('show');
  }

  inserir(){
    this.limparDados();
    $('#buttonSalvar').prop("disabled",false);
    $('#inputDescricao').focus();
  }

  limparDados(){
    this.id_turno = null;
    this.descricao = '';
    this.inicio_hora = '';
    this.inicio_hora_intervalo = '';
    this.fim_hora_intervalo = '';
    this.fim_hora = '';
    this.tipoAtual = 'I';
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.limparDados();

      this.tipoAtual = 'I';
    }
    $('#ModalExcluir').modal('toggle');
  }


  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 3,
      scrollY: "350px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

}
