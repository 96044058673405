import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, ViewChild } from '@angular/core';

import { User } from './../../models/user.model';
import { IMenuUser } from './../../models/IMenuUser.model';
import { Encryption } from './../../util/encryption';
import { HeaderComponent } from './../header.component';

@Injectable({
  providedIn: 'root'
})
export class MenuApiService {

  @ViewChild(HeaderComponent)
  modalHtml: HeaderComponent;

  private urlEtl2b = 'http://cloud.etl2b.com.br:41653/';

  infoUser: User;
  infoMenuUser: IMenuUser;

  constructor(
    private http: HttpClient,
    private encryptionApi: Encryption,
    private apiConfig: ApiConfigService
  ) { }

  ngOnInit(): void {
    
  }

  async getMenus(){
    const headers = {"Authorization": "Basic QXBwRXRsMmI6UE9PRkNJR01JUkxPU09NTFlQSlRIWUtFTkFNTE1Q", "Content-Type": "application/json"};

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let data = {
      "id_usuario": this.infoUser.id_usuario,
      "password": this.infoUser.password
    }
    // const result = await this.http.post<string>(`${this.urlEtl2b}get_estrutura_portal`, data, {headers, responseType: 'text' as 'json'}).toPromise().then(response => {
    //   if(response){
    //     let infoMenu = JSON.parse(response);
    //     this.infoMenuUser = infoMenu;

    //     var textEncoded = this.encryptionApi.encryptAll(this.infoMenuUser);
      
    //     sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoMenuUser'), textEncoded);
    //   }

    const result = await this.apiConfig.configServEtlPW.post<string, any>(`get_estrutura_portal`, data,).then(response => {
      if(response){
        let infoMenu = response.data;
        this.infoMenuUser = infoMenu;

        var textEncoded = this.encryptionApi.encryptAll(this.infoMenuUser);
      
        sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoMenuUser'), textEncoded);
      }

      return response;
    });
  }

  async setDashboard(idDashboard){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    this.infoUser.descricao_dashboard = idDashboard;

    var textEncoded = this.encryptionApi.encryptAll(this.infoUser);
      
    sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), textEncoded);
  }
}
