import { Injectable } from '@angular/core';

import { User } from './../../../models/user.model';
import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Encryption } from 'src/app/util/encryption';
import { IPedido } from '../../../models/IPedidos';

@Injectable({
  providedIn: 'root'
})
export class ListaPedidosFornecedorService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  listaOpcoes(empresaSelect: any){

    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_usuario: this.infoUser.id_usuario
    }

    return this.configApi.configServEtl.post<string, any>('get_info_geral_filtros',data,).then( response => {
      return response.data;
    }).catch( error => {
      console.log(error);
    })
  }

  ListasPedidos(empresaSelect: string, cnpjFornecedor: string, dataInicial: string, dataFinal: string, status_pedido: string = 'T'){
    let infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    let idCliente;
    let idEmpresa;
    let idEmpresaFilial;

    if(empresaSelect){
      idCliente = empresaSelect.split('_')[0];
      idEmpresa = empresaSelect.split('_')[1];
      idEmpresaFilial = empresaSelect.split('_')[2];
    }

    const data = {
      id_cliente_agent: idCliente? idCliente : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_cliente,
      id_empresa: idEmpresa? idEmpresa : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_empresa,
      id_filial_loja: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      id_filial_loja_empresa: idEmpresaFilial? idEmpresaFilial : this.infoUser.clientes.filter(e => e.selecionado == true)[0].id_loja_empresa,
      cnpj_fornecedor: cnpjFornecedor,
      data_de: dataInicial.replace(/-/g,''),
      data_ate: dataFinal.replace(/-/g,''),
      status_pedido: status_pedido,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<IPedido[], any>('get_pedidos_checkme_portal',data,).then( response => {
      return response.data.pedidos;
    }).catch( error =>{
      console.log(error);
    })
  }

  setPedidos(idPedido: string, status: string){

    const data = {
      id_pedido: idPedido,
      status: status,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<any>('set_pedidos_checkme',data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }
}
