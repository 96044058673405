import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { faEdit, faPlus, faTrash, faTimes, faLock, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FormatText } from './../../util/formt-text';
import { CadastroApiService } from './../service/cadastro-api.service';
import { IUsuarios } from './../../models/IUsuarioCad.models';
import { User } from './../../models/user.model';
import { Encryption } from './../../util/encryption';

declare let $: any;

type IObjeto ={
  nome: string;
  valor: string;
}

type IEmpresasLocal ={
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  cnpj: string;
  razao: string;
  nome_fantasia: string;
  logradouro: string;
  numero: string;
  bairro: string;
  id_municipio: string;
  municipio: string;
  id_estado: string;
  estado: string;
  pais: string;
  habilitado: boolean;
}

type IDepartamentoLocal ={
  id_departamento: string;
  descricao: string;
  habilitado: boolean;
}

type IFornecedoresLocal ={
  id_fornecedor: string;
  cnpj: string;
  fantasia: string;
  razao: string;
  habilitado: boolean;
}

type ITurnosLocal ={
  id_turno: string;
  descricao: string;
  inicio_hora: string;
  inicio_hora_intervalo: string;
  fim_hora_intervalo: string;
  fim_hora: string;
  habilitado: boolean;
}

type IFuncoesLocal ={
  id_funcao: string;
  descricao: string;
  habilitado: boolean;
}

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  faCopy = faCopy;
  faEdit = faEdit;
  faPlus = faPlus;
  faLock = faLock;
  faTrash = faTrash;
  faTimes = faTimes;

  infoUser: User;
  usuarioAtual: IUsuarios;

  usuarios: IUsuarios[];
  usuariosEmpresas: IEmpresasLocal[] = [];
  usuariosFuncoes: IFuncoesLocal[] = [];
  usuariosTurnos: ITurnosLocal[] = [];
  usuariosDepartamentos: IDepartamentoLocal[] = [];
  usuariosFornecedores: IFornecedoresLocal[] = [];

  empresas: IEmpresasLocal[]  = [];
  empresaAtual: string;

  funcoes: IFuncoesLocal[]  = [];
  funcaoAtual: string;

  turnos: ITurnosLocal[]  = [];
  turnoAtual: string;

  departamentos: IDepartamentoLocal[] = [];
  departamentoAtual: string;

  fornecedores: IFornecedoresLocal[] = [];
  fornecedorAtual: string;

  id_usuario: string;
  cpf: string;
  nome: string;
  telefone: string;
  num_atividades: number;
  perm_ativ_fora: string;
  permAtivForaValores: any[] = [{nome: 'SIM', valor: 'S'},{nome: 'NAO', valor: 'N'}];
  hora_inicio: string;
  hora_fim: string;
  tipo_evidencias: string;
  tipoEvidenciaValores: any[] = [{nome: 'FOTOS', valor: 'T'},{nome: 'LISTA', valor: 'L'}];
  email: string;
  nivel: string;
  nivelValores: IObjeto[] = [{nome: 'Master', valor: '1'},{nome: 'Diretoria', valor: '2'},{nome: 'Gerente de Loja', valor: '3'},{nome: 'Encarregado de Seção', valor: '4'},{nome: 'Abastecedor', valor: '5'},{nome: 'Promotor', valor: '6'}];
  numAtividades: number = 0;
  ativaForaHorario: string = '2';
  ativaForaHoraVals: IObjeto[] = [{nome: 'Sim', valor: '1'},{nome: 'Não',valor:'2'}];
  horaInicio: string;
  horaFim: string;
  tipoEvidencia: string = '1';
  tipoEvidenciaVals: IObjeto[] = [{nome: 'Fotos', valor: '1'},{nome: 'Lista Situações',valor:'2'}];
  status: string = "";
  statusValores: any[] = [{nome: 'ATIVO', valor: 'A'},{nome: 'INATIVO', valor: 'I'}];

  tipoAtual: string;
  new_pass: string = '';


  constructor(
    private serviceApi: CadastroApiService,
    private encryptionApi: Encryption,
    private formatTextApi: FormatText
  ) { }

  ngOnInit(): void {

    var infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));

    this.nivelValores = this.nivelValores.filter(e => e.valor > this.infoUser.nivel);

    this.tipoAtual = 'I';
    $('#buttonSalvar').prop("disabled",true);

    this.loadMasks();
    this.configDataTable();
    this.buscaDados();
  }

  buscaDados(){
    this.serviceApi.getDados('get_usuarios_portal').then(response => {
      this.usuarios = response.usuarios;
      this.rerender();
    });
    this.addEmpresa();
    this.addDepartamento();
    this.addFuncao();
    this.addTurno();
    this.addFornecedores();
  }

  editar(idUsuario){
    var usuarioSel = this.usuarios.filter(e => e.id_usuario == idUsuario)[0];
    this.tipoAtual = 'E';
    this.id_usuario = usuarioSel.id_usuario;
    this.cpf = usuarioSel.cpf;
    this.nome = usuarioSel.nome;
    this.telefone = usuarioSel.telefone;
    this.email = usuarioSel.email;
    this.nivel = usuarioSel.nivel;
    this.status = usuarioSel.status;
    this.perm_ativ_fora = usuarioSel.perm_ativ_fora;
    this.tipo_evidencias = usuarioSel.tipo_evidencia;
    this.num_atividades = usuarioSel.num_atividades;
    this.hora_inicio = usuarioSel.hora_inicio;
    this.hora_fim = usuarioSel.hora_fim;
    
    // this.usuariosDepartamentos = usuarioSel.departamentos;
    // this.usuariosEmpresas = usuarioSel.empresas;
    this.usuariosEmpresas = this.empresas;
    this.usuariosEmpresas.map(e => {
      if(usuarioSel.empresas.filter(p => p.cnpj == e.cnpj).length>0){
        e.habilitado = true;
      }else{
        e.habilitado = false;
      }
    });

    this.usuariosDepartamentos = this.departamentos;
    this.usuariosDepartamentos.map(e => {
      if(usuarioSel.departamentos.filter(p => p.id_departamento == e.id_departamento).length>0){
        e.habilitado = true;
      }else{
        e.habilitado = false;
      }
    });

    this.usuariosFuncoes = this.funcoes;
    this.usuariosFuncoes.map(e => {
      if(usuarioSel.funcoes.filter(p => p.id_funcao == e.id_funcao).length>0){
        e.habilitado = true;
      }else{
        e.habilitado = false;
      }
    });

    this.usuariosTurnos = this.turnos;
    this.usuariosTurnos.map(e => {
      if(usuarioSel.turnos.filter(p => p.id_turno == e.id_turno).length>0){
        e.habilitado = true;
      }else{
        e.habilitado = false;
      }
    });

    this.usuariosFornecedores = this.fornecedores;
    this.usuariosFornecedores.map(e => {
      if(usuarioSel.fornecedores.filter(p => p.id_fornecedor == e.id_fornecedor).length>0){
        e.habilitado = true;
      }else{
        e.habilitado = false;
      }
    });

    $('#buttonSalvar').prop("disabled",false);
    window.scrollTo(0, 0);

  }

  async salvar(){
    if((this.cpf && this.nome) || this.tipoAtual == 'D'){
      this.usuarioAtual = { 
        id_usuario: this.id_usuario,
        cpf: this.cpf,
        nome: this.nome.toUpperCase(),
        telefone:  this.telefone,
        email:  this.email,
        nivel:  this.nivel,
        num_atividades: this.num_atividades,
        perm_ativ_fora: this.perm_ativ_fora,
        hora_inicio: this.hora_inicio,
        hora_fim: this.hora_fim,
        tipo_evidencia: this.tipo_evidencias,
        status:  this.status,
        empresas:  this.usuariosEmpresas.filter(e => e.habilitado),
        funcoes:  this.usuariosFuncoes.filter(e => e.habilitado),
        turnos:  this.usuariosTurnos.filter(e => e.habilitado),
        departamentos: this.usuariosDepartamentos.filter(e => e.habilitado),
        fornecedores: this.usuariosFornecedores.filter(e => e.habilitado),
        password: ''
      }
      console.log(this.usuarioAtual);
      console.log(this.usuariosFornecedores);
      console.log(this.usuariosFornecedores.filter(e => e.habilitado));
      await this.serviceApi.postDadosUsuarios(this.usuarioAtual, this.tipoAtual).then(response =>{
        if(response.statusText=='OK'){
          this.buscaDados();
          this.limparDados();
        }
      });
    }
  }

  limparDados(){
    this.id_usuario = null;
    this.cpf = '';
    this.nome = '';
    this.telefone = '';
    this.email = '';
    this.nivel = '';
    this.status = '';
    this.tipoAtual = 'I';
    this.perm_ativ_fora = 'S';
    this.num_atividades = 0;
    this.tipo_evidencias = 'T';
    this.hora_inicio = '00:00';
    this.hora_fim = '23:59';

    this.usuariosEmpresas.map(e => { e.habilitado = false });
    this.usuariosFuncoes.map(e => { e.habilitado = false });
    this.usuariosTurnos.map(e => { e.habilitado = false });
    this.usuariosDepartamentos.map(e => { e.habilitado = false });
    this.usuariosFornecedores.map(e => { e.habilitado = false });
  }

  inserir(){
    this.limparDados();
    $('#buttonSalvar').prop("disabled",false);
    $('#inputCpf').focus();
  }

  modalNovaSenha(idUsuario){

    this.serviceApi.getNovaSenha(idUsuario).then( result => {
      this.new_pass = result;
      $('#ModalAlterPass').modal('show');
    });
  }

  modalPergunta(idUsuario){
    var usuarioSelecionado = this.usuarios.filter(e => e.id_usuario == idUsuario)[0];
    this.tipoAtual = 'D';
    this.id_usuario = usuarioSelecionado.id_usuario;
    this.cpf = '';
    this.nome = '';
    this.telefone = '';
    this.email = '';
    this.nivel = '';
    this.status = '';
    this.perm_ativ_fora = 'S';
    this.num_atividades = 0;
    this.tipo_evidencias = 'T';
    this.hora_inicio = '00:00';
    this.hora_fim = '23:59';
    this.usuariosEmpresas = [];
    this.usuariosFuncoes = [];
    this.usuariosTurnos = [];
    this.usuariosDepartamentos = [];
    this.usuariosFornecedores = [];

    $('#ModalExcluir').modal('show');
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.id_usuario = null;
      this.tipoAtual = 'I';
    }
    $('#ModalExcluir').modal('toggle');
  }

  remover(idChamada, id){
    if(idChamada=='empresa'){
      this.usuariosEmpresas = this.usuariosEmpresas.filter(e => e.id_cliente != id);
    }else if(idChamada=='funcao'){
      this.usuariosFuncoes = this.usuariosFuncoes.filter(e => e.id_funcao != id);
    }else if(idChamada=='turno'){
      this.usuariosTurnos = this.usuariosTurnos.filter(e => e.id_turno != id);
    }else if(idChamada=='departamento'){
      this.usuariosDepartamentos = this.usuariosDepartamentos.filter(e => e.id_departamento != id);
    }else if(idChamada=='fornecedores'){
      this.usuariosFornecedores = this.usuariosFornecedores.filter(e => e.id_fornecedor != id);
    }
  }

  addEmpresa(){
    this.serviceApi.getDados('get_companies_portal').then(response => {
      this.empresas = response.empresas;
      this.usuariosEmpresas = this.empresas;
      // $('#ModalAddEmpresa').modal('show');
    });
  }
  addFuncao(){
    this.serviceApi.getFuncoes('get_function_operator_portal').then(response => {
      this.funcoes = response.funcoes;
      this.usuariosFuncoes = this.funcoes;
      // $('#ModalAddFuncao').modal('show');
    });
  }
  addTurno(){
    this.serviceApi.getTurnos('get_turn_operator_portal').then(response => {
      this.turnos = response.turnos;
      this.usuariosTurnos = this.turnos;
      // $('#ModalAddTurno').modal('show');
    });
  }
  addDepartamento(){
    this.serviceApi.getDepartametos('get_departamentos_portal').then(response => {
      this.departamentos = response.departamentos;
      this.usuariosDepartamentos = this.departamentos;
      // $('#ModalAddDepartamento').modal('show');
    });
  }

  addFornecedores(){
    this.serviceApi.getFornecedores('get_fornecedores_portal').then(response => {
      this.fornecedores = response.fornecedores;
      this.usuariosFornecedores = this.fornecedores;
      // $('#ModalAddDepartamento').modal('show');
    });
  }

  adicionaEmpresa(){
    var usuarioSel = this.usuarios.filter(e => e.id_usuario == this.id_usuario)[0];
    if(!usuarioSel){
      let empresaSelecionada = this.empresas.filter(e => e.id_cliente == this.empresaAtual)[0];
      this.usuariosEmpresas.push(empresaSelecionada);
      $('#ModalAddEmpresa').modal('toggle');
    }else{
      if(usuarioSel.empresas.filter(e => e.id_cliente == this.empresaAtual)[0]){
        alert('Essa empresa ja pertence ao usuario!');
      }else{

        let empresaSelecionada = this.empresas.filter(e => e.id_cliente == this.empresaAtual)[0];
        
        this.usuariosEmpresas.push(empresaSelecionada);
        
        $('#ModalAddEmpresa').modal('toggle');
      }
    }
  }
  adicionaFuncao(){
    var usuarioSel = this.usuarios.filter(e => e.id_usuario == this.id_usuario)[0];
    if(!usuarioSel){
      let funcaoSelecionada = this.funcoes.filter(e => e.id_funcao == this.funcaoAtual)[0];
      this.usuariosFuncoes.push(funcaoSelecionada);
      $('#ModalAddFuncao').modal('toggle');
    }else{
      if(usuarioSel.funcoes.filter(e => e.id_funcao == this.funcaoAtual)[0]){
        alert('Essa função ja pertence as funções deste usuario!');
      }else{

        let funcaoSelecionada = this.funcoes.filter(e => e.id_funcao == this.funcaoAtual)[0];
        
        this.usuariosFuncoes.push(funcaoSelecionada);
        
        $('#ModalAddFuncao').modal('toggle');
      }
    }
  }
  adicionaTurno(){
    var usuarioSel = this.usuarios.filter(e => e.id_usuario == this.id_usuario)[0];
    if(!usuarioSel){
      let turnoSelecionada = this.turnos.filter(e => e.id_turno == this.turnoAtual)[0];
      this.usuariosTurnos.push(turnoSelecionada);
      $('#ModalAddTurno').modal('toggle');
    }else{
      if(usuarioSel.turnos.filter(e => e.id_turno == this.turnoAtual)[0]){
        alert('Essa turno ja pertence aos turnos do usuario!');
      }else{

        let turnoSelecionada = this.turnos.filter(e => e.id_turno == this.turnoAtual)[0];
        
        this.usuariosTurnos.push(turnoSelecionada);
        
        $('#ModalAddTurno').modal('toggle');
      }
    }
  }

  adicionaDepartamento(){
    var usuarioSel = this.usuarios.filter(e => e.id_usuario == this.id_usuario)[0];
    if(!usuarioSel){
      let departamentoSelecionado = this.departamentos.filter(e => e.id_departamento == this.departamentoAtual)[0];
      this.usuariosDepartamentos.push(departamentoSelecionado);
      $('#ModalAddDepartamento').modal('toggle');
    }else{
      if(usuarioSel.departamentos.filter(e => e.id_departamento == this.departamentoAtual)[0]){
        alert('Essa turno ja pertence aos turnos do usuario!');
      }else{

        let departamentoSelecionado = this.departamentos.filter(e => e.id_departamento == this.departamentoAtual)[0];
        
        this.usuariosDepartamentos.push(departamentoSelecionado);
        
        $('#ModalAddDepartamento').modal('toggle');
      }
    }
  }

  marcaOpcao(id, chave){
    if(id=='EMPRESAS'){
      this.usuariosEmpresas.filter(e => e.cnpj==chave)[0].habilitado = !this.usuariosEmpresas.filter(e => e.cnpj==chave)[0].habilitado;
    }
    if(id=='DEPARTAMENTO'){
      this.usuariosDepartamentos.filter(e => e.id_departamento==chave)[0].habilitado = !this.usuariosDepartamentos.filter(e => e.id_departamento==chave)[0].habilitado;
    }
    if(id=='FUNCOES'){
      this.usuariosFuncoes.filter(e => e.id_funcao==chave)[0].habilitado = !this.usuariosFuncoes.filter(e => e.id_funcao==chave)[0].habilitado;
    }
    if(id=='TURNOS'){
      this.usuariosTurnos.filter(e => e.id_turno==chave)[0].habilitado = !this.usuariosTurnos.filter(e => e.id_turno==chave)[0].habilitado;
    }
    if(id=='FORNECEDOR'){
      this.usuariosFornecedores.filter(e => e.id_fornecedor==chave)[0].habilitado = !this.usuariosFornecedores.filter(e => e.id_fornecedor==chave)[0].habilitado;
    }
  }


  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      scrollY: "500px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadMasks(){
    $("#inputCpf").keydown(function(){
      try {
        $("#inputCpf").unmask();
      } catch (e) {}
  
      $("#inputCpf").mask("999.999.999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
      $('#buttonSalvar').prop("disabled",false);
    });

    $("#cnpjCad").keydown(function(){
      try {
          $("#cnpjCad").unmask();
      } catch (e) {}
  
      $("#cnpjCad").mask("99.999.999/9999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#telefoneCad").keydown(function(){
      try {
          $("#telefoneCad").unmask();
      } catch (e) {}
  
      $("#telefoneCad").mask("(99)9999-999999");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });
  }

  copyToClipboard(){
    navigator.clipboard.writeText(this.new_pass);
  }
}
