import { HeaderComponent } from './../header/header.component';
import { Component, OnInit } from '@angular/core';

import { EmbeddedApiService } from './service/embedded-api.service';
import { Several } from './../util/several';

declare let $: any;

@Component({
  selector: 'app-embedded',
  templateUrl: './embedded.component.html',
  styleUrls: ['./embedded.component.css']
})
export class EmbeddedComponent implements OnInit {

  isLoading = true;

  constructor(
    private embeddedApi: EmbeddedApiService,
    private severalApi: Several
  ) { }

  ngOnInit(): void {
    HeaderComponent.emitirTrocaPergunta.subscribe(idPergunta =>{
      this.isLoading = true;
      setTimeout(()=>{
        this.isLoading = false;
      }, 5000);
      this.carregaEmbedded(idPergunta);
    })
    this.carregaEmbedded();
    
    if(this.severalApi.myBrowser()==='Firefox'){
      $('#htmlEmbedded').addClass('border-firefox');
    }

    setTimeout(()=>{
      this.isLoading = false;
    }, 5000);
  }

  async carregaEmbedded(idPergunta = ''){
    const connectionEmbedded = await this.embeddedApi.getToken();
    const htmlEmbedded = await this.embeddedApi.getEmbeddedDash(idPergunta);

    const getUrlBIText = htmlEmbedded.replace('embedData.accessToken;','embedData.accessToken;embedData.settings={navContentPaneEnabled: false};');

    const url = await URL.createObjectURL(new Blob([htmlEmbedded], { type: "text/html" }));

    $('#htmlEmbedded').attr('src', url);
      
    
    // $('#htmlEmbedded').append(htmlEmbedded);

  }
  
}
