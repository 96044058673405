import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { IEmpresas } from './../../models/IEmpresas.model';
import { CadastroApiService } from './../service/cadastro-api.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormatText } from './../../util/formt-text';
import { GeolocationEtl } from 'src/app/util/geolocationEtl';

declare let $: any;

type Estato = {
  id: number;
  nome: string;
  sigla: string;
}
type Municipio = {
  id: number;
  nome: string;
}

@Component({
  selector: 'app-lojas',
  templateUrl: './lojas.component.html',
  styleUrls: ['./lojas.component.css']
})
export class LojasComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  lojas: IEmpresas[] = [];
  lojaAtual: IEmpresas;
  dtTrigger: Subject<any> = new Subject<any>();

  selectedCar: number;
  id_cliente: string = null;
  id_empresa: string = null;
  id_loja_empresa: string = null;
  cnpj:string;
  razao:string;
  nome_fantasia:string;
  logradouro:string;
  numero:string;
  bairro:string;
  municipio:string;
  estado:string;
  pais:string;
  tipoAtual: string;

  estados:Estato[] = [];
  idEstado: number = 0;
  municipios:Municipio[] = [];
  idMunicipio: number = 0;
  paises: any[] = [{nome: 'BRASIL', valor: 'BRASIL'}];

  faEdit = faEdit;
  faTrash = faTrash;

  grupos = [];

  constructor(
    private serviceApi: CadastroApiService,
    private formatTextApi: FormatText,
    private geolocationEtl: GeolocationEtl
  ) { }

  ngOnInit(): void {
    this.tipoAtual = 'I';
    $('#buttonSalvar').prop("disabled",true);

    this.configDataTable();
    this.buscaDados();
    this.loadMasks();
  }

  buscaDados(){
    this.serviceApi.getDados('get_companies_portal').then(response => {
      this.lojas = response.empresas;
      this.rerender();
    });

    this.grupos = [];
    this.serviceApi.getDados('get_groups_portal').then(response => {
      response.grupos.map(res => {
        this.grupos.push({id: res['id_grupo'], name: res['nome']});
      })
    });
  
    this.geolocationEtl.geoStates().subscribe(response => {
      this.estados = JSON.parse(response);
    });
  }

  async salvar(){

    if(this.cnpj && this.razao && this.nome_fantasia && this.logradouro && this.numero && this.bairro && this.idEstado && this.idMunicipio && this.pais){
      this.lojaAtual = { 
        id_cliente: this.id_cliente,
        id_loja_empresa: this.id_loja_empresa,
        id_empresa: this.id_empresa,
        razao: this.razao,
        cnpj: this.cnpj.replace(/[\/\*\|\.\<\>\?\"-]/gi, ''),
        nome_fantasia: this.nome_fantasia,
        logradouro: this.logradouro,
        numero: this.numero,
        bairro: this.bairro,
        id_municipio: this.idMunicipio.toString(),
        municipio: this.municipio,
        id_estado: this.idEstado.toString(),
        estado: this.estado,
        pais: this.pais
      }
  
      await this.serviceApi.postDadosLojas(this.lojaAtual, this.tipoAtual).then(res =>{
        if(res['success']){
          this.buscaDados();
          this.limparDados();
        }
      });
    }

  }

  inserir(){
    this.limparDados();
    $('#buttonSalvar').prop("disabled",false);
    $('#inputCnpj').focus();
  }

  editar(idCliente){
    var lojaSelecionada = this.lojas.filter(e => e.id_cliente == idCliente)[0];
    this.tipoAtual = 'E';
    this.id_cliente = lojaSelecionada.id_cliente;
    this.id_empresa = lojaSelecionada.id_empresa;
    this.id_loja_empresa = lojaSelecionada.id_loja_empresa;
    this.razao = lojaSelecionada.razao;
    this.cnpj = lojaSelecionada.cnpj;
    this.nome_fantasia = lojaSelecionada.nome_fantasia;
    this.logradouro = lojaSelecionada.logradouro;
    this.numero = lojaSelecionada.numero;
    this.bairro = lojaSelecionada.bairro;
    this.idEstado = Number(lojaSelecionada.id_estado);
    this.estado = lojaSelecionada.estado;
    this.selecionaEstado();
    this.municipio = lojaSelecionada.municipio;
    this.idMunicipio = Number(lojaSelecionada.id_municipio);
    this.pais = lojaSelecionada.pais;

    $('#buttonSalvar').prop("disabled",false);
  }

  modalPergunta(idCliente){
    var lojaSelecionada = this.lojas.filter(e => e.id_cliente == idCliente)[0];
    this.tipoAtual = 'D';
    this.id_cliente = lojaSelecionada.id_cliente;
    this.id_empresa = lojaSelecionada.id_empresa;
    this.id_loja_empresa = lojaSelecionada.id_loja_empresa;
    this.razao = lojaSelecionada.razao;
    this.cnpj = lojaSelecionada.cnpj;
    this.nome_fantasia = lojaSelecionada.nome_fantasia;
    this.logradouro = lojaSelecionada.logradouro;
    this.numero = lojaSelecionada.numero;
    this.bairro = lojaSelecionada.bairro;
    this.idMunicipio = Number(lojaSelecionada.id_municipio);
    this.municipio = lojaSelecionada.municipio;
    this.idEstado = Number(lojaSelecionada.id_estado);
    this.estado = lojaSelecionada.estado;
    this.pais = lojaSelecionada.pais;

    $('#ModalExcluir').modal('show');
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.limparDados();
    }
    $('#ModalExcluir').modal('toggle');
  }

  selecionaEstado(){
    this.geolocationEtl.geoCounty(this.idEstado.toString()).subscribe(response => {
      this.municipios = JSON.parse(response);
      this.estado = $('#inputEstado option:selected').text();
    });
  }

  selecionaMunicipio(){
    this.municipio = $('#inputMunicipio option:selected').text();
  }

  limparDados(){
    this.id_cliente = null;
    this.id_empresa = null;
    this.id_loja_empresa = null;
    this.razao = '';
    this.cnpj = '';
    this.nome_fantasia = '';
    this.logradouro = '';
    this.numero = '';
    this.bairro = '';
    this.idMunicipio = 0;
    this.municipio = '';
    this.idEstado = 0;
    this.estado = '';
    this.pais = '';

    this.tipoAtual = 'I';
  }

  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      scrollY: "350px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadMasks(){
    $("#cpfCad").keydown(function(){
      try {
        $("#cpfCad").unmask();
      } catch (e) {}
  
      $("#cpfCad").mask("999.999.999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#inputCnpj").keydown(function(){
      try {
          $("#inputCnpj").unmask();
      } catch (e) {}
  
      $("#inputCnpj").mask("99.999.999/9999-99");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });

    $("#telefoneCad").keydown(function(){
      try {
          $("#telefoneCad").unmask();
      } catch (e) {}
  
      $("#telefoneCad").mask("(99)9999-999999");
  
      // ajustando foco
      var elem = this;
      setTimeout(function(){
          // mudo a posição do seletor
          elem.selectionStart = elem.selectionEnd = 10000;
      }, 0);
      // reaplico o valor para mudar o foco
      var currentValue = $(this).val();
      $(this).val('');
      $(this).val(currentValue);
    });
  }
}
