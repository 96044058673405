import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Component, OnInit } from '@angular/core';

type IGruposInfo ={
  grupo: string;
  usuarios: IUserDados[];
}

type IUserDados ={
  nome: string;
  link_linkeding: string;
  link_instagram: string;
}

@Component({
  selector: 'app-quem-somos',
  templateUrl: './quem-somos.component.html',
  styleUrls: ['./quem-somos.component.css']
})
export class QuemSomosComponent implements OnInit {

  gruposInfo:IGruposInfo[] = [];

  constructor(
    private apiConfig: ApiConfigService
  ) { }

  ngOnInit(): void {
    this.carregaDados();
  }

  carregaDados(){
    this.apiConfig.configServEtl.post('get_quem_somos_portal',{},).then( response => {
      this.gruposInfo = response.data;
    })
  }

}
