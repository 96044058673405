<!--?xml version="1.0" standalone="no"?-->
<div class="svg">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 521.000000 521.000000" preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,521.000000) scale(0.100000,-0.100000)" fill="#1c2e4a" stroke="none">
            <path d="M3973 4627 c-259 -68 -295 -85 -326 -149 -31 -65 -100 -332 -101
            -388 0 -72 25 -123 85 -169 26 -20 48 -39 48 -42 1 -3 -37 -39 -83 -80 -47
            -41 -85 -78 -85 -84 0 -5 28 -31 62 -56 l63 -47 87 79 c48 44 91 79 95 79 5 0
            33 -18 63 -41 70 -54 108 -69 166 -69 63 0 492 112 537 140 19 11 44 35 55 53
            23 39 111 354 111 401 0 41 -17 93 -41 124 -21 27 -330 266 -372 288 -61 32
            -110 27 -364 -39z" class="svg-elem-1"></path>
        </g>
        <g transform="translate(0.000000,521.000000) scale(0.100000,-0.100000)" fill="#203354" stroke="none">
            <path d="M1305 4133 c-80 -20 -546 -168 -570 -180 -40 -20 -93 -71 -112 -107
            -9 -17 -45 -122 -80 -235 -56 -180 -64 -213 -60 -263 9 -105 39 -143 232 -291
            284 -219 264 -216 651 -98 148 46 288 94 311 107 58 33 99 87 124 161 11 35
            24 63 27 63 4 0 60 -11 126 -25 65 -14 120 -24 121 -23 1 2 11 37 22 79 l21
            76 -112 23 c-61 13 -116 25 -122 27 -7 3 -3 34 14 94 23 81 25 95 14 146 -20
            98 -46 128 -229 268 -92 71 -188 140 -213 152 -46 23 -128 36 -165 26z" class="svg-elem-2"></path>
        </g>
        <g transform="translate(0.000000,521.000000) scale(0.100000,-0.100000)" fill="#23395d" stroke="none">
            <path d="M2735 3721 c-165 -42 -313 -84 -330 -93 -32 -17 -73 -58 -98 -98 -28
            -44 -137 -458 -137 -520 0 -67 33 -141 81 -186 76 -69 442 -340 482 -356 23
            -9 62 -17 87 -17 25 0 187 35 360 79 341 86 368 97 422 175 37 53 148 455 148
            535 0 69 -33 141 -86 191 -67 62 -427 331 -469 350 -71 32 -135 24 -460 -60z" class="svg-elem-3"></path>
        </g>
        <g transform="translate(0.000000,521.000000) scale(0.100000,-0.100000)" fill="#192841" stroke="none">
            <path d="M3185 2413 c-33 -9 -61 -17 -63 -18 -1 -1 21 -66 49 -144 29 -78 50
            -143 48 -144 -2 -2 -70 -29 -151 -61 -129 -51 -152 -63 -191 -105 -24 -26 -49
            -63 -56 -83 -23 -69 -141 -663 -141 -709 0 -56 15 -102 49 -151 34 -49 493
            -424 551 -451 32 -15 64 -20 110 -20 59 1 96 13 382 126 174 69 330 133 346
            141 33 17 85 73 106 114 20 40 146 661 146 722 0 69 -16 121 -52 164 -36 44
            -489 416 -538 442 -25 13 -56 18 -110 19 -65 0 -89 -6 -184 -43 -60 -23 -110
            -41 -111 -40 -2 2 -23 59 -47 128 -51 141 -47 138 -143 113z" class="svg-elem-4"></path>
        </g>
    </svg>
</div>
    