import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { IFuncoes } from './../../models/IFuncoes.model';
import { CadastroApiService } from './../service/cadastro-api.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

@Component({
  selector: 'app-funcoes',
  templateUrl: './funcoes.component.html',
  styleUrls: ['./funcoes.component.css']
})
export class FuncoesComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  faEdit = faEdit;
  faTrash = faTrash;

  funcoes: IFuncoes[] = [];
  funcaoAtual: IFuncoes;

  selectedCar: number;
  id_funcao: string = null;
  descricao:string;
  tipoAtual: string;

  constructor(
    private serviceApi: CadastroApiService,
  ) { }

  ngOnInit(): void {
    this.tipoAtual = 'I';

    this.configDataTable();
    this.buscaDados();
  }

  buscaDados(){
    this.serviceApi.getDados('get_function_operator_portal').then(response => {
      this.funcoes = response.funcoes;
      this.rerender();
    });
  }

  async salvar(){

    if(this.descricao){
      this.funcaoAtual = { 
        id_funcao: this.id_funcao,
        descricao: this.descricao,
      }
  
      await this.serviceApi.postDadosFuncoes(this.funcaoAtual, this.tipoAtual).then(res =>{
        if(res['success']){
          this.buscaDados();
          this.limparDados();
        }
      });
    }

  }

  editar(idfuncao){
    var funcaoSelecionada = this.funcoes.filter(e => e.id_funcao == idfuncao )[0];
    this.tipoAtual = 'E';
    this.id_funcao = funcaoSelecionada.id_funcao;
    this.descricao = funcaoSelecionada.descricao;
  }

  modalPergunta(idfuncao){
    var funcaoSelecionada = this.funcoes.filter(e =>e.id_funcao == idfuncao )[0];
    this.tipoAtual = 'D';
    this.id_funcao = funcaoSelecionada.id_funcao;
    this.descricao = funcaoSelecionada.descricao;

    $('#ModalExcluir').modal('show');
  }

  inserir(){
    this.limparDados();
    $('#buttonSalvar').prop("disabled",false);
    $('#inputDescricao').focus();
  }

  limparDados(){
    this.id_funcao = null;
    this.descricao = '';
    this.tipoAtual = 'I';
  }

  excluir(exclui){
    if(exclui){
      this.salvar();
    }else{
      this.id_funcao = null;
      this.descricao = '';

      this.tipoAtual = 'I';
    }
    $('#ModalExcluir').modal('toggle');
  }

  configDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 3,
      scrollY: "350px",
      scrollCollapse: true,
      language:{
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        lengthMenu: "_MENU_ resultados por página",
        search: "Pesquisar",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

}