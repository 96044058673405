import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';

import { User } from './../models/user.model';
import { Encryption } from './../util/encryption';
import { FormatText } from './../util/formt-text';
import { Several } from './../util/several';

declare let $: any;

@Component({
  selector: 'app-seleciona-cliente',
  templateUrl: './seleciona-cliente.component.html',
  styleUrls: ['./seleciona-cliente.component.css']
})
export class SelecionaClienteComponent implements OnInit {

  @ViewChild('insideElement', { static: false }) insideElement;

  static emitirSelecionaCliente = new EventEmitter<string>();

  modalSelectCliente: boolean = false;
  infoUser:User;
  clientsUser:any[];
  modalOpened: boolean = false;

  constructor(
    private encryptionApi: Encryption,
    private formatTextApi: FormatText,
    private severalApi: Several
  ) { }

  ngOnInit(): void {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('click', this.onDocumentClick);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  openModal(){
    this.modalSelectCliente = true;
    this.carregaClientes();
  }

  closeModal(){
    $('.modal-content').addClass('slide-out-right');
    (async () => { 
      await this.severalApi.delay(900);
      this.modalSelectCliente = false;
      this.modalOpened = false;
    })();
    
  }

  carregaClientes(){
    var infoCript = sessionStorage.getItem(this.encryptionApi.ascii_to_hexa('infoUsuario'));
    this.infoUser = JSON.parse(this.encryptionApi.hex_to_ascii(atob(infoCript)));
    this.clientsUser = this.infoUser.clientes;
  }

  selecionaCliente(chave){
    this.clientsUser.map(e => {
      if(e.cnpj==chave && !e.selecionado){
        e.selecionado = true;
      }else if(e.cnpj==chave && e.selecionado){
        e.selecionado = false;
      }
    })

    this.salvaClienteSelecionado();

    SelecionaClienteComponent.emitirSelecionaCliente.emit();
  }

  salvaClienteSelecionado(){
    this.infoUser.clientes = this.clientsUser;

    var textEncoded = JSON.stringify(this.infoUser)
    textEncoded = this.encryptionApi.ascii_to_hexa(textEncoded);
    textEncoded = this.encryptionApi.encodeDecod(textEncoded);
    
    sessionStorage.setItem(this.encryptionApi.ascii_to_hexa('infoUsuario'), textEncoded);
  }

  protected onDocumentClick(event: MouseEvent) {

    var target = event.target as Element;

    if(this.insideElement){
      if (!this.insideElement.nativeElement.contains(event.target) && this.modalOpened) {
        this.closeModal();
        return;
      }else{
        this.modalOpened = true;
      }
    }
  }

}
