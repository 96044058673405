export class Encryption {
    encodeDecod(textoCript, decode = false){
        if(decode){
            return atob(textoCript);
        }else{
            return btoa(textoCript);
        }
    }

    ascii_to_hexa(str){
        var arr1 = [];
        for (var n = 0, l = str.length; n < l; n ++){
            var hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return arr1.join('');
    }

    hex_to_ascii(str1){
        var hex  = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }

    encryptAll(data){
        var dataString = JSON.stringify(data);
        var dataHex = this.ascii_to_hexa(dataString);
        var dataBase = this.encodeDecod(dataHex);

        return dataBase;
    }

    decryptAll(data){
        var dataBase = this.encodeDecod(data, true);
        var dataDecod = this.hex_to_ascii(dataBase);

        return dataDecod;
    }
}