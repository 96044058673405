<!-- <div class="container-fluid" style="height: 100vh;">
    <div class="row" style="height: 11vh;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="container-fluid">
        <div class="row home" style="height: 83vh;">
            <div class="col-md-2">
                <app-menu-lateral></app-menu-lateral>
            </div>
            <div class="col-md-10">
                <div class="dashboard card-medium">
                    <app-embedded></app-embedded>
                </div>
            </div>
            <!-- <div class="rotinas card-big">
                <div class="dashboard card-medium">
                    <iframe src="http://10.200.16.231:5513/teste_amarrado" frameborder="0" width="100%" height="100%" class="inframe-dashboard"></iframe>
                </div>
                <div class="alertBoard card-medium">
                    <h3>AlertBoard</h3>
                </div>
            </div>
            <div class="cadastros card-big">
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
            </div> 
        </div>
        <div class="row">
            <div class="alertBoard card-medium">
                <h3>AlertBoard</h3>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div> -->

<!--
<div class="container-fluid" style="height: 100vh;">
    <div class="row" style="height: 11vh;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="container-fluid">
        <div class="row home" style="height: 83vh;">
            <div class="col-md-2">
                <app-menu-lateral></app-menu-lateral>
            </div>
            <div class="col-md-10">
                <div class="dashboard card-medium">
                    <app-embedded></app-embedded>
                </div>
            </div>
            <!-- <div class="rotinas card-big">
                <div class="dashboard card-medium">
                    <iframe src="http://10.200.16.231:5513/teste_amarrado" frameborder="0" width="100%" height="100%" class="inframe-dashboard"></iframe>
                </div>
                <div class="alertBoard card-medium">
                    <h3>AlertBoard</h3>
                </div>
            </div>
            <div class="cadastros card-big">
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
                <div class="card-cadastro"></div>
            </div> 
        </div>
    </div>
    <!-- <app-footer></app-footer> 
</div>-->

<div class="container-fluid" style="height: 100vh; overflow: hidden;">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row embedded">
        <div class="dashboard card-medium">
            <app-embedded></app-embedded>
        </div>
    </div>
</div>