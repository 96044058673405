<div class="container-fluid" style="height: 100vh; overflow: hidden;">
  <div class="row" style="height: 70px;">
      <app-header style="width: 100%;"></app-header>
  </div>
      <div class="row home" style="height: 83vh;">
          <div class="container-form">
            <div class="linha-header">
              <h3>Cadastro de Funções</h3>
              <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
            </div>
              <div class="linha-campos">
                  <form  id="formFuncoes" action="#" ngNativeValidate autocomplete="off">
                      <div class="form-row">
                          <div class="form-group col-md-2">
                              <label for="inputDescricao">Descrição</label>
                              <input [(ngModel)]="descricao" name="descricao" type="text" class="form-control" id="inputDescricao" placeholder="Descricao" style="text-transform:uppercase" required>
                          </div>
                      </div>
                  </form>
                  <div class="controle-buttons">
                    <button (click)="limparDados()" class="btn btn-limpar">Limpar</button>
                    <button (click)="inserir()" class="btn btn-inserir">Inserir</button>
                    <button id="buttonSalvar" (click)="salvar()" class="btn btn-salvar">Salvar</button>
                  </div>
              </div>
              <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
              <div class="linha-dados">
                  <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
                      <thead>
                        <tr>
                          <th style="width: 40px;">ID Função</th>
                          <th style="width: 100px;">Descrição</th>
                          <th style="width: 80px;">Acoes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let funcao of funcoes">
                          <td>{{ funcao.id_funcao }}</td>
                          <td>{{ funcao.descricao }}</td>
                          <td class="acoes-linha">
                              <button type="button" class="btn btn-primary" (click)="editar(funcao.id_funcao)"><fa-icon [icon]="faEdit"></fa-icon></button>
                              <button type="button" class="btn btn-danger" (click)="modalPergunta(funcao.id_funcao)"><fa-icon [icon]="faTrash"></fa-icon></button>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    <div class="modal" tabindex="-1" role="dialog" id=ModalExcluir>
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Excluir registro</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Deseja realmente excluir o registro?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
              <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</div>