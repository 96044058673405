import { Injectable } from '@angular/core';

import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  constructor() { }

  public configServEtl = axios.create({
    baseURL: 'https://backend.terustec.com.br/'+(window.location.hostname.substr(0,window.location.hostname.indexOf('.')>0?window.location.hostname.indexOf('.'):99)=='localhost'?'cometa':window.location.hostname.substr(0,window.location.hostname.indexOf('.')>0?window.location.hostname.indexOf('.'):99)),/*http://cloud.etl2b.com.br:41653/',*/
    headers: {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"},
    timeout: 60000
  });

  public configServEtlPW = axios.create({
    baseURL: 'https://backend.terustec.com.br/'+(window.location.hostname.substr(0,window.location.hostname.indexOf('.')>0?window.location.hostname.indexOf('.'):99)=='localhost'?'cometa':window.location.hostname.substr(0,window.location.hostname.indexOf('.')>0?window.location.hostname.indexOf('.'):99))+'pw',/*http://cloud.etl2b.com.br:41653/',*/
    headers: {"Authorization": "Basic QXBwRXRsMmI6UE9PRkNJR01JUkxPU09NTFlQSlRIWUtFTkFNTE1Q", "Content-Type": "application/json"},
    timeout: 60000
  });

  public configServEtl2b = axios.create({
    baseURL: 'http://servicos.etl2b.com.br:41650/',/*http://cloud.etl2b.com.br:41653/',*/
    headers: {"Authorization": "Basic ZXRsMmI6MTIzNDU2Nzg5", "Content-Type": "application/json"},
    timeout: 60000
  });
}
