<div *ngIf="modalSelectCliente" class="modal fade show" tabindex="-1" style="display: block; padding-right: 17px;background: rgb(51 52 53 / 61%);" aria-modal="true" role="dialog">
    <div class="modal-dialog">
        <div #insideElement class="slide-in-left modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalPopoversLabel">Selecione um CNPJ:</h5>
                <button type="button" class="btn botao-fechar" (click)="closeModal()">Fechar</button>
            </div>
            <div class="modal-body">
                <ul>
                    <li *ngFor="let clientUser of clientsUser" class="cards-clientes {{clientUser.selecionado? 'selected':''}}" (click)="selecionaCliente(clientUser.cnpj)">
                        <h5>{{clientUser.nome}}</h5>
                        <span>Cnpj: {{this.formatTextApi.formatCnpjCpf(clientUser.cnpj)}}</span>
                        <span>Endereço: {{clientUser.endereco}}</span>
                        <div *ngIf="clientUser.selecionado" class="info-selected">
                            <span>selecionado</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
