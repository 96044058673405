import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

type Estatos = {
    id: number;
    nome: string;
    sigla: string;
}
type Municipios = {
    id: number;
    nome: string;
}

@Injectable({
    providedIn: 'root'
})

export class GeolocationEtl {

    constructor(
        private http: HttpClient,
    ) { }

    geoStates(id:string = ''){

        const headers = {"Content-Type": "application/json"};

        return this.http.get<string>('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+id,{headers, responseType: 'text' as 'json'});
    }

    geoCounty(idEstado:string = ''){

        const headers = {"Content-Type": "application/json"};

        return this.http.get<string>('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+idEstado+'/municipios',{headers, responseType: 'text' as 'json'});
    }
}