import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AccountApiService } from './service/account-api.service';

declare let $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

    showIndex = true;
    myStyle: object = {};
    myParams: object = {};
    paramss: object = {};
    width = 100;
    height = 100;
    username: string = '';
    password: string = '';
    usernameCad: string;
    passwordCad: string;
    emailCad: string;
    cnpjCad: string;

    constructor(
        private router: Router,
        private accountApi: AccountApiService
    ) { }

    GetStarted() {
        this.showIndex = false;
    }

    ngOnInit(): void {
        // this.configParticles()

        $("#usuaname").focusin(function(){
            $('#isValid').css('display','none');
            $('.contact-form-login input.submit').css('margin-top','20px');
            $('.contact-form-login input.submit').css('margin-bottom','20px');
        });
        $("#password").focusin(function(){
            $('#isValid').css('display','none');
            $('.contact-form-login input.submit').css('margin-top','20px');
            $('.contact-form-login input.submit').css('margin-bottom','20px');
        });
    }

    async login(){

        this.clearStorage();

        const logou = await this.accountApi.postCredentials(this.username,this.password);

        if(logou){
            this.router.navigate(['/home']);
        }else{
            $('#isValid').css('display','block');
            $('.contact-form-login input.submit').css('margin-top','5px');
            $('.contact-form-login input.submit').css('margin-bottom','5px');
        }
    }

    cadastro(){

    }

    clearStorage(){
        localStorage.clear();
    }

    regisaterPanel(){
        $('.connexion').addClass('remove-section');
        $('.enregistrer').removeClass('active-section');
        $('.btn-enregistrer').removeClass('active');
        $('.btn-connexion').addClass('active');
    };
    
    loginPanel() {
        $('.connexion').removeClass('remove-section');
        $('.enregistrer').addClass('active-section');	
        $('.btn-enregistrer').addClass('active');
        $('.btn-connexion').removeClass('active');
    };

    configParticles(){
        // ParticlesJS Config.
        this.myStyle = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        };

        this.myParams = {
        particles: {
            number: {
            value: 60,
            density: {
                enable: true,
                value_area: 800
            }
            },
            color: {
            value: '#ffffff'
            },
            shape: {
            type: 'circle',
            stroke: {
                width: 0,
                color: '#000000'
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
            }
            },
            opacity: {
            value: 0.1,
            random: false,
            anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
            }
            },
            size: {
            value: 6,
            random: false,
            anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
            }
            },
            line_linked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.1,
            width: 2
            },
            move: {
            enable: true,
            speed: 1.5,
            direction: 'top',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
            }
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
            onhover: {
                enable: false,
                mode: 'repulse'
            },
            onclick: {
                enable: false,
                mode: 'push'
            },
            resize: true
            },
            modes: {
            grab: {
                distance: 400,
                line_linked: {
                opacity: 1
                }
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
            }
        },
        retina_detect: true
        };
    }
}
