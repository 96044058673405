<div class="container-fluid" style="height: 100vh; overflow: hidden;">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
        <div class="row home" style="height: 83vh;">
            <div class="container-form">
              <div class="linha-header">
                <h3>Cadastro de Turnos</h3>
                <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
              </div>
                <div class="linha-campos">
                    <form  id="formTurnos" action="#" ngNativeValidate autocomplete="off">
                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label for="inputDescricao">Descrição</label>
                                <input [(ngModel)]="descricao" name="descricao" type="text" class="form-control" id="inputDescricao" placeholder="Descricao" style="text-transform:uppercase" required>
                            </div>
                            <div class="form-group col-md-2">
                                <label for="inputInicioHora">Inicio Hora</label>
                                <input [(ngModel)]="inicio_hora" name="inicio_hora" type="text" class="form-control" id="inputInicioHora" placeholder="Inicio Hora" style="text-transform:uppercase" required>
                            </div>
                            <div class="form-group col-md-2">
                                <label for="inputInicioHoraIntervalo">Inicio Intervalo Hora</label>
                                <input [(ngModel)]="inicio_hora_intervalo" name="inicio_hora_intervalo" type="text" class="form-control" id="inputInicioHoraIntervalo" placeholder="Inicio Intervalo Hora" style="text-transform:uppercase" required>
                            </div>
                            <div class="form-group col-md-2">
                                <label for="inputInicioFimIntervalo">Fim Intervalo Hora</label>
                                <input [(ngModel)]="fim_hora_intervalo" name="fim_hora_intervalo" type="text" class="form-control" id="inputInicioFimIntervalo" placeholder="Fim Intervalo Hora" style="text-transform:uppercase" required>
                            </div>
                            <div class="form-group col-md-2">
                                <label for="inputFimHora">Fim Hora</label>
                                <input [(ngModel)]="fim_hora" name="fim_hora" type="text" class="form-control" id="inputFimHora" placeholder="Fim Hora" style="text-transform:uppercase" required>
                            </div>
                        </div>
                    </form>
                    <div class="controle-buttons">
                      <button (click)="limparDados()" class="btn btn-limpar">Limpar</button>
                      <button (click)="inserir()" class="btn btn-inserir">Inserir</button>
                      <button id="buttonSalvar" (click)="salvar()" class="btn btn-salvar">Salvar</button>
                    </div>
                </div>
                <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
                <div class="linha-dados">
                    <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
                        <thead>
                          <tr>
                            <th style="width: 40px;">ID Turno</th>
                            <th style="width: 100px;">Descrição</th>
                            <th style="width: 80px;">Acoes</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let turno of turnos">
                            <td>{{ turno.id_turno }}</td>
                            <td>{{ turno.descricao }}</td>
                            <td class="acoes-linha">
                                <button type="button" class="btn btn-primary" (click)="editar(turno.id_turno)"><fa-icon [icon]="faEdit"></fa-icon></button>
                                <button type="button" class="btn btn-danger" (click)="modalPergunta(turno.id_turno)"><fa-icon [icon]="faTrash"></fa-icon></button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <div class="modal" tabindex="-1" role="dialog" id=ModalExcluir>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Excluir registro</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Deseja realmente excluir o registro?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
                <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
      </div>
      <!-- <app-footer></app-footer> -->
  </div>