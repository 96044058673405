<!-- <!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>Política de Privacidade - Aplicativo Task</title>
</head>
<body style="height: 100vh;
overflow: scroll;
padding: 10px;">
  <h1>Política de Privacidade - Aplicativo Task</h1>
  <p><strong>Última atualização:</strong> 05-jun-2023</p>
  <p>A Terus.Tec valoriza a privacidade de seus usuários e está empenhada em proteger as informações pessoais coletadas por meio do aplicativo Task. Esta Política de Privacidade descreve como as informações são coletadas, usadas, compartilhadas e protegidas ao utilizar o nosso aplicativo. Ao utilizar o aplicativo, você concorda com os termos desta Política de Privacidade.</p>
  
  <h2>1. Informações Coletadas</h2>
  <h3>1.1 Informações Pessoais</h3>
  <p>O aplicativo Task coleta informações pessoais fornecidas pelos usuários durante o processo de cadastro, como nome, endereço de e-mail, número de telefone e outras informações relevantes para o uso adequado do aplicativo. Essas informações são necessárias para identificar e autenticar os usuários e fornecer um serviço personalizado.</p>
  
  <h3>1.2 Informações de Uso</h3>
  <p>Além das informações pessoais, o aplicativo também coleta informações de uso, como logs de atividades, dados de desempenho e métricas de uso. Essas informações são usadas para melhorar a funcionalidade do aplicativo, solucionar problemas técnicos, realizar análises de uso e melhorar a experiência do usuário.</p>
  
  <h2>2. Uso das Informações</h2>
  <h3>2.1 Fornecimento de Serviços</h3>
  <p>As informações coletadas são usadas para fornecer os serviços do aplicativo Task, incluindo o gerenciamento de tarefas, o acompanhamento do progresso, a comunicação entre usuários e outras funcionalidades relacionadas.</p>
  
  <h3>2.2 Comunicações</h3>
  <p>Podemos utilizar as informações fornecidas para enviar comunicações relacionadas ao uso do aplicativo, como notificações de tarefas, atualizações do aplicativo e informações sobre novos recursos. Os usuários podem optar por não receber essas comunicações de marketing, mas ainda receberão mensagens relacionadas ao serviço.</p>
  
  <h3>2.3 Melhoria do Aplicativo</h3>
  <p>As informações coletadas são utilizadas para melhorar o aplicativo, sua usabilidade e a experiência do usuário. Isso pode incluir o uso de informações agregadas e anônimas para análises e pesquisas.</p>
  
  <h2>3. Compartilhamento de Informações</h2>
  <h3>3.1 Terceiros Selecionados</h3>
  <p>A Terus.Tec pode compartilhar informações com terceiros selecionados, como provedores de serviços de hospedagem, desenvolvedores de software e outros parceiros envolvidos no fornecimento e suporte do aplicativo. Esses terceiros estão sujeitos a obrigações contratuais de confidencialidade e só podem utilizar as informações conforme necessário para prestar os serviços contratados.</p>
  
  <h3>3.2 Requisitos Legais</h3>
  <p>Em determinadas circunstâncias, a Terus.Tec poderá ser obrigada a compartilhar informações pessoais para cumprir requisitos legais, responder a solicitações governamentais ou proteger seus direitos, propriedade ou segurança.</p>
  
  <h2>4. Segurança das Informações</h2>
  <p>A Terus.Tec implementa medidas de segurança adequadas para proteger as informações coletadas por meio do aplicativo Task contra acesso não autorizado, divulgação, alteração ou destruição. No entanto, nenhum sistema é completamente seguro, e a Terus.Tec não pode garantir a segurança absoluta das informações fornecidas pelos usuários.</p>
  
  <h2>5. Cookies e Tecnologias Similares</h2>
  <p>O aplicativo Task pode utilizar cookies e tecnologias similares para melhorar a experiência do usuário, personalizar conteúdos e anúncios, fornecer recursos de análise e rastrear o uso do aplicativo. Os usuários têm a opção de controlar o uso de cookies por meio das configurações do seu dispositivo ou navegador.</p>
  
  <h2>6. Alterações nesta Política de Privacidade</h2>
  <p>A Terus.Tec reserva-se o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento. Recomendamos que os usuários revisem periodicamente esta página para estar cientes de quaisquer alterações. O uso contínuo do aplicativo após a publicação de alterações constitui a aceitação dessas alterações.</p>
  
  <h2>7. Contato</h2>
  <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre o uso de suas informações pessoais, entre em contato conosco por meio dos canais de suporte fornecidos no aplicativo Task.</p>
</body>
</html> -->

<!DOCTYPE html>
    <html>
    <head>
      <meta charset='utf-8'>
      <meta name='viewport' content='width=device-width'>
      <title>Privacy Policy</title>
      <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    </head>
    <body style="height: 100vh;
    overflow: scroll;
    padding: 10px;">
    <strong>Privacy Policy</strong> <p>
                  Para idioma português role a pagina.</p>
                  <p>
                  TerusTec built the TerusApp app as
                  a Commercial app. This SERVICE is provided by
                  TerusTec  and is intended for use as
                  is.
                </p> <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p> <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  TerusApp unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                  For a better experience, while using our Service, we
                  may require you to provide us with certain personally
                  identifiable information, including but not limited to photographic images, name, email, password, scan, Geographical location, Cookie ID. The information that
                  we request will be retained by us and used as described in this privacy policy.
                </p> <div><p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app
                  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
                  We want to inform you that whenever you
                  use our Service, in a case of an error in the app
                  we collect data and information (through third-party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing our Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                  We may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  We want to inform users of this Service
                  that these third parties have access to their Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                  We value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and we cannot
                  guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us.
                  Therefore, we strongly advise you to review the
                  Privacy Policy of these websites. We have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p> <p><strong>Children’s Privacy</strong></p> <div><p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions.
                  </p></div> <!----> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                  We may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2023-06-25</p> <p><strong>Contact Us</strong></p> <p>
                  If you have any questions or suggestions about our
                  Privacy Policy, do not hesitate to contact us at tecnologia@terustec.com.br.
                </p>

                <br>
                <br>
                <br>

                <strong>Política de privacidade</strong> <p>
                  A TerusTec construiu o aplicativo TerusApp como
                   um aplicativo comercial. Este SERVIÇO é fornecido por
                   TerusTec e destina-se ao uso como
                   é.
                  
                </p> <p>
                  Esta página é usada para informar os visitantes sobre nossas
                   políticas com a coleta, uso e divulgação de Dados Pessoais
                   Informações se alguém decidir usar nosso Serviço.
                </p> <p>
                  Se você optar por usar nosso Serviço, concorda em
                   a coleta e o uso de informações relacionadas a este
                   política. As informações pessoais que coletamos são
                   usados para fornecer e melhorar o Serviço. Não usaremos ou compartilharemos suas informações com
                   qualquer pessoa, exceto conforme descrito nesta Política de Privacidade.
                </p> <p>
                  Os termos usados nesta Política de Privacidade têm os mesmos significados
                   como em nossos Termos e Condições, que podem ser acessados em
                   TerusApp, a menos que definido de outra forma nesta Política de Privacidade.
                </p> <p><strong>Coleta e uso de informações</strong></p> <p>
                   Para uma melhor experiência, ao usar nosso Serviço,
                   pode exigir que você nos forneça certos dados pessoais
                   informações identificáveis, incluindo, entre outras, imagens fotográficas, nome, e-mail, senha, digitalização, localização geográfica, ID do cookie. A informação que
                   solicitamos serão retidos por nós e usados conforme descrito nesta política de privacidade.
                </p> <div><p>
                  O aplicativo usa serviços de terceiros que podem coletar
                  informações usadas para identificá-lo.
                  </p> <p>
                    Link para a política de privacidade de provedores de serviços terceirizados usados
                     pelo aplicativo
                  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
                    Queremos informar que sempre que você
                    usar nosso Serviço, em caso de erro no aplicativo
                    coletamos dados e informações (através de terceiros
                    produtos) em seu telefone chamado Log Data. Esses dados de registro podem
                    inclua informações como o Protocolo de Internet do seu dispositivo
                    endereço (“IP”), nome do dispositivo, versão do sistema operacional,
                    configuração do aplicativo ao utilizar nosso Serviço,
                    a hora e a data de seu uso do Serviço e outras
                    Estatisticas.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookies são arquivos com uma pequena quantidade de dados que são
                   comumente usados como identificadores exclusivos anônimos. Estes são enviados
                   para o seu navegador a partir dos sites que você visita e são
                   armazenados na memória interna do seu dispositivo.
                </p> <p>
                  Este Serviço não usa esses “cookies” explicitamente. No entanto,
                   o aplicativo pode usar código de terceiros e bibliotecas que usam
                   “cookies” para coletar informações e melhorar seus serviços.
                   Você tem a opção de aceitar ou recusar esses cookies
                   e saiba quando um cookie está sendo enviado para o seu dispositivo. Se você
                   optar por recusar nossos cookies, talvez você não consiga usar alguns
                   partes deste Serviço.
                </p> <p><strong>Provedores de serviço</strong></p> <p>
                  Podemos empregar empresas terceirizadas e
                   pessoas pelas seguintes razões:
                </p> <ul><li>Para facilitar nosso Serviço;</li> <li>Para fornecer o Serviço em nosso nome;</li> <li>Para realizar serviços relacionados ao Serviço; ou</li> <li>Para nos ajudar a analisar como nosso Serviço é usado.</li></ul> <p>
                  Queremos informar os usuários deste Serviço
                   que esses terceiros tenham acesso aos seus dados pessoais
                   Informação. O motivo é executar as tarefas atribuídas a
                   eles em nosso nome. No entanto, eles são obrigados a não
                   divulgar ou usar as informações para qualquer outra finalidade.
                </p> <p><strong>Segurança</strong></p> <p>
                  Valorizamos sua confiança em nos fornecer seu
                   Informações Pessoais, portanto, estamos nos esforçando para usar comercialmente
                   meios aceitáveis de protegê-lo. Mas lembre-se que nenhum método
                   de transmissão pela internet, ou método de transmissão eletrônica
                   armazenamento é 100% seguro e confiável, e não podemos
                   garantir a sua segurança absoluta.
                </p> <p><strong>Links para outros sites</strong></p> <p>
                  Este Serviço pode conter links para outros sites. Se você clicar em
                   um link de terceiros, você será direcionado para esse site. Observação
                   que esses sites externos não são operados por nós.
                   Portanto, recomendamos fortemente que você revise o
                   Política de Privacidade desses sites. Nós temos
                   nenhum controle e não assume nenhuma responsabilidade pelo conteúdo,
                   políticas de privacidade ou práticas de quaisquer sites de terceiros ou
                   Serviços.
                </p> <p><strong>Children’s Privacy</strong></p> <div><p>
                  Estes Serviços não se dirigem a menores de 13 anos.
                  Nós não coletamos intencionalmente pessoalmente
                  informações identificáveis de crianças menores de 13 anos de idade. Dentro do estojo
                  descobrimos que uma criança menor de 13 anos forneceu
                  nós com informações pessoais, nós imediatamente
                  exclua isso de nossos servidores. Se você é pai ou responsável
                  e você está ciente de que seu filho nos forneceu
                  informações pessoais, entre em contato conosco para que
                  seremos capazes de fazer as ações necessárias.
                  </p></div> <!----> <p><strong>Mudanças nesta Política de Privacidade</strong></p> <p>
                    Podemos atualizar nossa Política de Privacidade de
                    tempo ao tempo. Assim, você é aconselhado a rever esta página
                    periodicamente para quaisquer alterações. Vamos
                    notificá-lo sobre quaisquer alterações, publicando a nova Política de Privacidade em
                    esta página.
                </p> <p>Esta política é efetiva a partir de 2023-06-25</p> <p><strong>Contate-nos</strong></p> <p>
                  Se você tiver alguma dúvida ou sugestão sobre nossos
                   Política de Privacidade, não hesite em nos contatar em tecnologia@terustec.com.br.
                </p>
    </body>
    </html>