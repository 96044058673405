<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #24223e;">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-5 titulo-painel">
                        <h3>Terus Teams</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDataInical">Empresa:</label>
                        <select [(ngModel)]="empresaSelecionado" id="input_empresa" class="form-control" aria-label="Default select example">
                            <option *ngIf="empresasGeral.length>1"></option>
                            <option *ngFor="let itemEmpresa of empresasGeral" value="{{itemEmpresa.id_cliente+'_'+itemEmpresa.id_empresa+'_'+itemEmpresa.id_loja_empresa}}">{{itemEmpresa.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDataInical">Data início:</label>
                        <input [(ngModel)]="dataInicial" name="dataInicial" type="date" class="form-control" id="inputDataInical">
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputFim">Data fim:</label>
                        <input [(ngModel)]="dataFinal" name="dataFinal" type="date" class="form-control" id="inputDataFinal">
                    </div>
                    <div class="col-md-1 button-pesquisa">
                        <button class="btn btn-success" type="submit" (click)="atualizaPesquisa()"><fa-icon [icon]="faSearch"></fa-icon></button>
                    </div>
                </div>
                <hr>
            </div>
            <div class="grupo-filtros">
                <div class="row">
                    <div class="form-group col-md-2 periodo">
                        <label for="inputDepartamento">Departamento:</label>
                        <select [(ngModel)]="departamentoSelecionado" id="input_departamento" class="form-control" aria-label="Default select example" (change)="buscaOpcoes('DEPARTAMENTO');filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemDepartamento of departamentos" value="{{itemDepartamento.id_departamento}}">{{itemDepartamento.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputSecao">Seção:</label>
                        <select [(ngModel)]="secaoSelecionado" [disabled]="departamentoSelecionado!=''?false:true" id="input_secao" class="form-control" aria-label="Default select example" (change)="buscaOpcoes('SECAO');filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemSecao of secoes" value="{{itemSecao.id_secao}}">{{itemSecao.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputCategoria">Categoria:</label>
                        <select [(ngModel)]="categoriaSelecionado" [disabled]="secaoSelecionado!=''?false:true" id="input_categoria" class="form-control" aria-label="Default select example" (change)="buscaOpcoes('CATEGORIA');filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemCategoria of categorias" value="{{itemCategoria.id_categoria}}">{{itemCategoria.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputSegmento">Segmento:</label>
                        <select [(ngModel)]="segmentoSelecionado" [disabled]="categoriaSelecionado!=''?false:true" id="input_segmento" class="form-control" aria-label="Default select example" (change)="filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemSegmento of segmentos" value="{{itemSegmento.id_segmento}}">{{itemSegmento.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputOperador">Operador:</label>
                        <select [(ngModel)]="operadorSelecionado" id="input_operador" class="form-control" aria-label="Default select example" (change)="filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemOperador of operadorFiltro" value="{{itemOperador.id_usuario}}">{{itemOperador.nome}}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group col-md-2 periodo">
                        <label for="inputStatus">Status:</label>
                        <select [(ngModel)]="statusSelecionado" id="input_status" class="form-control" aria-label="Default select example" (change)="filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemStatus of status" value="{{itemStatus.id}}">{{itemStatus.descricao}}</option>
                        </select>
                    </div> -->
                    <div class="form-group col-md-2 periodo">
                        <label for="inputAtividades">Tipo Atividades:</label>
                        <select [(ngModel)]="atividadesSelecionado" id="input_atividades" class="form-control" aria-label="Default select example" (change)="filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemsAtividades of atividades" value="{{itemsAtividades.id_atividade}}">{{itemsAtividades.descricao}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2 periodo">
                        <label for="inputAlertas">Tipo Alertas:</label>
                        <select [(ngModel)]="alertasSelecionado" id="input_Alertas" class="form-control" aria-label="Default select example" (change)="filtraListaLogs()">
                            <option></option>
                            <option *ngFor="let itemsAlertas of alertas" value="{{itemsAlertas.id_alerta}}">{{itemsAlertas.descricao_alerta}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="dashboards">
                <div class="card">
                    <span class="tituloDash">Totais</span>
                    <span class="valorDash">{{totaisAtividades.total_atividades.toLocaleString(undefined,{minimumFractionDigits: 0} )}}</span>
                </div>
                <div class="card">
                    <span class="tituloDash">Abertas</span>
                    <span class="valorDash">{{totaisAtividades.total_ativ_abertas.toLocaleString(undefined,{minimumFractionDigits: 0} )}}</span>
                </div>
                <div class="card">
                    <span class="tituloDash">Concluídas</span>
                    <span class="valorDash">{{totaisAtividades.total_ativ_concluidas.toLocaleString(undefined,{minimumFractionDigits: 0} )}}</span>
                </div>
                <div class="card">
                    <span class="tituloDash">Executando</span>
                    <span class="valorDash">{{totaisAtividades.total_ativ_andamento.toLocaleString(undefined,{minimumFractionDigits: 0} )}}</span>
                </div>
                <div class="card">
                    <span class="tituloDash">Abandonadas</span>
                    <span class="valorDash">{{totaisAtividades.total_ativ_abandonada.toLocaleString(undefined,{minimumFractionDigits: 0} )}}</span>
                </div>
            </div>
            <div class="tabela-dados">
                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Usuário</th>
                            <th scope="col">Atividade</th>
                            <th scope="col">Departamento / Seção / Categoria / Segmento</th>
                            <th scope="col">Produto</th>
                            <th scope="col">Data Criação</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let itemLogAtividade of logAtividades" >
                        <tr (click)="escondeInfo('linha'+itemLogAtividade.id)">
                            <td>{{itemLogAtividade.nome | uppercase}}</td>
                            <td>{{itemLogAtividade.descricao_atividade}}</td>
                            <td>{{itemLogAtividade.descricao_departamento}} - {{itemLogAtividade.descricao_secao}} - {{itemLogAtividade.descricao_categoria}} - {{itemLogAtividade.descricao_segmento}}</td>
                            <td>{{itemLogAtividade.descricao_produto}} ({{itemLogAtividade.codigo_produto}})</td>
                            <td>{{itemLogAtividade.data_fim}}</td>
                            <td>{{itemLogAtividade.descricao_status | uppercase}}</td>
                        </tr>
                        <tr id="linha{{itemLogAtividade.id}}" class="esconde">
                            <td colspan="8">
                                <div class="descricao-produto">
                                    <span>{{itemLogAtividade.ean_produto}} - {{itemLogAtividade.descricao_produto}} ({{itemLogAtividade.codigo_produto}})</span>
                                </div>
                                <div class="dados-linha">
                                    <div *ngFor="let itemChecklist of itemLogAtividade.checklist ; last as isLast" class="grupo-atividade">
                                        <div class="checklist-imagem">
                                            <div class="quadro-checklist">
                                                <span class="titulo-checklist">{{itemChecklist.descricao}}</span>
                                                <div class="quadro-imagem">
                                                    <span *ngIf="itemChecklist.nivel_abastecido!='' && itemChecklist.id_checklist!='3'" class="resultado-cheklist {{itemChecklist.foto?'':'semFoto'}} {{itemChecklist.nivel_abastecido.indexOf('_')>0?'tamanhoAbast':''}}">{{itemChecklist.nivel_abastecido.replace('_',' COM ')}}</span>
                                                    <img *ngIf="itemChecklist.foto" id="foto{{itemLogAtividade.id+'_'+itemChecklist.id_checklist}}" class="shimmer" alt=""> 
                                                </div>
                                                <span class="periodo-checklist">{{itemChecklist.data_conclusao}}</span>
                                            </div>
                                        </div>
                                        <div class="seta-passo" *ngIf="!isLast">
                                            <fa-icon [icon]="faAngleDoubleRight"></fa-icon>
                                        </div>
                                    </div>
                                    <div *ngIf="itemLogAtividade.checklist.length==0 && itemLogAtividade.id_alerta==13" class="sem-informacao">
                                        <span>Produto Abastecido</span>
                                    </div>
                                </div>
                            </td>
                            
                            <!-- <td colspan="7">
                                <div class="dados-imagens">
                                    <div class="imagem-anterior">
                                        <label>Situação Anterior</label>
                                        <!-- <img src="data:image/jpeg;base64," alt=""> 
                                        <img src="http://www.meupositivo.com.br/doseujeito/wp-content/uploads/2019/12/como-funciona-rede-neural-computadores.jpg" alt="">
                                    </div>
                                    <div class="imagem-nova">
                                        <label>Situação Nova</label>
                                        <img src="http://www.meupositivo.com.br/doseujeito/wp-content/uploads/2019/12/como-funciona-rede-neural-computadores.jpg" alt="">
                                    </div>
                                </div>
                            </td> -->
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
      <!-- <app-footer></app-footer> -->
  </div>