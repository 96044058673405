import { FormatDate } from './../../util/format-date';
import { Component, OnInit } from '@angular/core';
import { faSearch, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { AtividadesApiService } from './service/atividades-api.service';
import { ILogAtividade } from './../../models/ILogAtividades';

declare let $: any;

type ITotaisPainel = {
  total_atividades: number;
  total_ativ_concluidas: number;
  total_ativ_andamento: number;
  total_ativ_abertas: number;
  total_ativ_abandonada: number;
}

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IDepartamentos = {
  id_departamento: string;
  descricao: string;
}

type ISecoes = {
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type IAlertas = {
  id_alerta: number;
  descricao_alerta: string;
  id_atividade: string;
  descricao: string;
}

type ICategorias = {
  id_categoria: string;
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type ISegmentos = {
  id_segmento: string;
  id_categoria: string;
  id_secao: string;
  id_departamento: string;
  descricao: string;
}

type IOperadorFiltro = {
  id_usuario: string;
  nome: string;
}

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type IDataFoto = {
  foto: string;
}

@Component({
  selector: 'app-lista-atividades',
  templateUrl: './lista-atividades.component.html',
  styleUrls: ['./lista-atividades.component.css']
})
export class ListaAtividadesComponent implements OnInit {

  faSearch = faSearch;
  faAngleDoubleRight = faAngleDoubleRight;

  totaisAtividades: ITotaisPainel = {
    total_atividades: 0,
    total_ativ_concluidas: 0,
    total_ativ_andamento: 0,
    total_ativ_abertas: 0,
    total_ativ_abandonada: 0
  };
  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  ativaLoading: boolean = true;

  logAtividadesGeral: ILogAtividade[] = [];
  logAtividades: ILogAtividade[] = [];

  empresasGeral: IEmpresas[] = [];
  empresaSelecionado: string = '';
  departamentos: IDepartamentos[] = [];
  departamentoSelecionado: string = '';
  secoesGeral: ISecoes[] = [];
  secoes: ISecoes[] = [];
  secaoSelecionado: string = '';
  categoriasGeral: ICategorias[] = [];
  categorias: ICategorias[] = [];
  categoriaSelecionado: string = '';
  segmentosGeral: ISegmentos[] = [];
  segmentos: ISegmentos[] = [];
  segmentoSelecionado: string = '';
  operadorFiltro: IOperadorFiltro[] = [];
  operadorSelecionado: string = '';

  alertasSelecionado: number = 0;
  alertas = [];

  atividadesSelecionado: string = '';
  atividades = [];

  statusSelecionado: string = 'U';
  // status = [
  //   {
  //     id: 'X',
  //     descricao: 'Abandonado Automatico',
  //   },
  //   {
  //     id: 'Z',
  //     descricao: 'Abandonado Manual',
  //   },
  //   {
  //     id: 'P',
  //     descricao: 'Em Aberto',
  //   },
  //   {
  //     id: 'U',
  //     descricao: 'Concluido',
  //   }
  // ]
  opcoes: any[] = [];

  constructor(
    private atividadesApi: AtividadesApiService,
    private formatDate: FormatDate
  ) { }

  ngOnInit(): void {
    var dataAtuais = sessionStorage.getItem('datasPesq');
    if(dataAtuais){
      this.datasAtuais = JSON.parse(dataAtuais);
      this.dataInicial = this.datasAtuais.dataDe;
      this.dataFinal = this.datasAtuais.dataAte;
    }else{
      this.dataInicial = this.formatDate.formatDateForInput(new Date(), 1);
      this.dataFinal = this.formatDate.formatDateForInput(new Date(), 0);

      this.datasAtuais = {
        dataDe: this.dataInicial,
        dataAte: this.dataFinal
      }
  
      sessionStorage.setItem('datasPesq', JSON.stringify(this.datasAtuais));
    }

    var empresaAtual = sessionStorage.getItem('etl@logEmpresa');
    if(empresaAtual){
      this.empresaSelecionado = empresaAtual;
    }

    this.atividadesApi.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.departamentos = result.departamentos;
      this.secoesGeral = result.secoes;
      this.categoriasGeral = result.categorias;
      this.segmentosGeral = result.segmentos;
      this.operadorFiltro = result.usuarios;
      this.empresasGeral = result.empresas;
      this.atividades = result.atividades;
      this.alertas = result.alertas;

      if(this.empresaSelecionado==''){
        this.empresaSelecionado = this.empresasGeral[0].id_cliente+'_'+this.empresasGeral[0].id_empresa+'_'+this.empresasGeral[0].id_loja_empresa;
      }
      
      this.buscaDadosTotais();
    })

  }

  atualizaPesquisa(){
    this.ativaLoading = true;

    this.datasAtuais ={
      dataDe: this.dataInicial,
      dataAte: this.dataFinal
    }

    sessionStorage.setItem('datasPesq', JSON.stringify(this.datasAtuais));
    sessionStorage.setItem('etl@logEmpresa', this.empresaSelecionado);

    this.atividadesApi.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.departamentos = result.departamentos;
      this.secoesGeral = result.secoes;
      this.categoriasGeral = result.categorias;
      this.segmentosGeral = result.segmentos;
      this.operadorFiltro = result.usuarios;
      this.empresasGeral = result.empresas;
      this.alertas = result.alertas;
      
      this.buscaDadosTotais();
    })

  }

  async buscaDadosTotais(atualizaLogs: boolean = true){
    await this.atividadesApi.totaisAtividades(this.dataInicial, this.dataFinal,this.empresaSelecionado,this.departamentoSelecionado,this.secaoSelecionado,this.categoriaSelecionado,this.segmentoSelecionado,this.operadorSelecionado,this.statusSelecionado, this.atividadesSelecionado, this.alertasSelecionado).then( result =>{
      this.totaisAtividades = result;
    })
    if(atualizaLogs){
      await this.atividadesApi.logAtividades(this.dataInicial, this.dataFinal, this.departamentoSelecionado, this.secaoSelecionado, this.categoriaSelecionado, this.segmentoSelecionado, this.operadorSelecionado, this.statusSelecionado, this.empresaSelecionado, this.atividadesSelecionado, this.alertasSelecionado).then( result => {
        this.logAtividades = result;
        this.logAtividadesGeral = result;
      })
    }
    this.ativaLoading = false;
  }

  buscaOpcoes(opcao:string){
    if(opcao=='DEPARTAMENTO'){
      this.secoes = this.secoesGeral.filter(e => e.id_departamento == this.departamentoSelecionado);
    }else if (opcao=='SECAO'){
      this.categorias = this.categoriasGeral.filter(e => e.id_departamento == this.departamentoSelecionado && e.id_secao == this.secaoSelecionado)
    }else if (opcao=='CATEGORIA'){
      this.segmentos = this.segmentosGeral.filter(e => e.id_departamento == this.departamentoSelecionado && e.id_secao == this.secaoSelecionado && e.id_categoria == this.categoriaSelecionado)
    }

    if(this.departamentoSelecionado==''){
      this.secaoSelecionado = '';
      this.secoes = [];
    }
    if(this.secaoSelecionado==''){
      this.categoriaSelecionado = '';
      this.categorias = [];
    }
    if(this.categoriaSelecionado==''){
      this.segmentos = [];
    }
  }

  filtraListaLogs(){
    this.ativaLoading = true;
    this.logAtividades = this.logAtividadesGeral;

    if (this.departamentoSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_departamento == this.departamentoSelecionado);
    }
    if (this.secaoSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_secao == this.secaoSelecionado);
    }
    if (this.categoriaSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_categoria == this.categoriaSelecionado);
    }
    if (this.segmentoSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_segmento == this.segmentoSelecionado);
    }
    if (this.operadorSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_usuario == this.operadorSelecionado);
    }
    if (this.statusSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.status == this.statusSelecionado);
    }
    if (this.atividadesSelecionado!=''){
      this.logAtividades = this.logAtividades.filter(e => e.id_atividade == this.atividadesSelecionado);
    }
    if (this.alertasSelecionado!=0){
      this.logAtividades = this.logAtividades.filter(e => e.id_alerta == this.alertasSelecionado);
    }

    this.buscaDadosTotais(false);
    
  }

  buscaImagem(id){
    id = id.replace('linha','');

    this.logAtividadesGeral.filter(e => e.id == id)[0].checklist.filter(p => p.foto).map( checklists => {
      this.atividadesApi.imagensAtividades(id,checklists.id_checklist).then( result => {
        $('#foto'+id+'_'+result.id_checklist).attr('src','data:image/jpeg;base64,'+result.foto);
        $('#foto'+id+'_'+result.id_checklist).removeClass('shimmer');
      });
    })
  }

  escondeInfo(id) {
    if ($( "#"+id ).hasClass( 'esconde' )) {
      $( "#"+id ).removeClass('esconde');
      this.buscaImagem(id);
    } else {                    
      $( "#"+id ).addClass('esconde');
    }
  }

}
