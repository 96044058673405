import { Injectable } from '@angular/core';

import { User } from './../../../models/user.model';
import { ApiConfigService } from 'src/app/sevice/api-config.service';
import { Encryption } from 'src/app/util/encryption';
import { IPedido } from '../../../models/IPedidos';

@Injectable({
  providedIn: 'root'
})
export class AprovaPedidosCheckmeService {

  infoUser:User;

  constructor(
    private configApi: ApiConfigService,
    private encryptionApi: Encryption
  ) { }

  ListaItemPedido(idPedido: string){
    
    const data = {
      id_pedido: idPedido,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<IPedido[], any>('get_pedidos_checkme_item_portal',data,).then( response => {
      return response.data.pedidos;
    }).catch( error =>{
      console.log(error);
    })
  }

  setPedidosStatus(idPedido: string, status: string){

    const data = {
      id_pedido: idPedido,
      status: status,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<any>('set_pedidos_checkme',data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }

  setPedidos(idPedido: string, pedido: IPedido, status: string){

    const data = {
      id_pedido: idPedido,
      pedido: pedido,
      status: status,
      versao: "2.0.1"
    }

    return this.configApi.configServEtl.post<any>('set_pedidos_checkme',data,).then( response => {
      return response.data.mensagem;
    }).catch( error =>{
      console.log(error);
    })
  }
}
