export class Several{
    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    myBrowser() { 
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
          return 'Opera';
        }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
          return 'Chrome';
        }else if(navigator.userAgent.indexOf("Safari") != -1){
          return 'Safari';
        }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
          return 'Firefox';
        }else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent))){
          return 'IE'; 
        }else{
          return 'unknown';
        }
    }
    
    mySo(){
        if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) {
            return "Windows 10";
        }else if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
            return "Windows 8";
        }else if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
            return "Windows 7";
        }else if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
            return "Windows Vista";
        }else if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
            return "Windows XP";
        }else if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
            return "Windows 2000";
        }else if (window.navigator.userAgent.indexOf("Mac")            != -1) {
            return "Mac/iOS";
        }else if (window.navigator.userAgent.indexOf("X11")            != -1) {
            return "UNIX";
        }else if (window.navigator.userAgent.indexOf("Linux")          != -1) {
            return "Linux";
        }else{
            return 'unknown';
        }
    }
}