import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faThLarge, faBuilding, faAngleDown, faBars, faChartBar, faAddressCard, faClipboardList } from '@fortawesome/free-solid-svg-icons';


declare let $: any;

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  faBars = faBars;
  faThLarge = faThLarge;
  faBuilding = faBuilding;
  faChartBar = faChartBar;
  faAngleDown = faAngleDown;
  faAddressCard = faAddressCard;
  faClipboardList = faClipboardList;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.marcaOpcao();
  }

  navegacao(caminho){
    this.router.navigate(['/'+caminho]);
  }

  espandeMenu(menu){
    if($('.'+menu).hasClass('showMenu')){
      $('.'+menu).removeClass('showMenu');
    }else{
      $('.'+menu).addClass('showMenu');
    }
  }

  espandeSideBar(){
    if($('.sidebar').hasClass('fechar')){
      $('.sidebar').removeClass('fechar');
    }else{
      $('.sidebar').addClass('fechar');
    }
  }

  marcaOpcao(){
    switch(this.router.url) { 
      case '/cadGrupos': { 
         $('#linkCadGrupos').addClass('active');
         break; 
      }
      case '/cadEmpresas': { 
        $('#linkCadEmpresas').addClass('active');
        break; 
      }
      case '/cadContatos': { 
        $('#linkCadContatos').addClass('active');
        break; 
      }
      case '/cadFuncoes': { 
        $('#linkCadFuncoes').addClass('active');
        break; 
      }
      case '/cadTurnos': { 
        $('#linkCadTurnos').addClass('active');
        break; 
      }
      case '/cadSecoes': { 
        $('#linkCadSecoes').addClass('active');
        break; 
      }
      case '/cadUsuarios': { 
        $('#linkCadUsuarios').addClass('active');
        break; 
      }
      case '/cadAtividades': { 
        $('#linkCadAtividades').addClass('active');
        break; 
      }
      case '/cadScripts': { 
        $('#linkCadScripts').addClass('active');
        break; 
      }
      case '/acompanhamento': { 
        $('#linkAcompanhamento').addClass('active');
        break; 
      }
      default: { 
        $('#linkHome').addClass('active');
         break; 
      } 
    }
  }

}
