<!-- <ul>
    <li><a id="linkHome" (click)="navegacao('home')">Home</a></li>
    <li><a id="linkCadGrupos" (click)="navegacao('cadGrupos')">Cadastro de Grupo Corporativo</a></li>
    <li><a id="linkCadEmpresas" (click)="navegacao('cadEmpresas')">Cadastro de Empresas</a></li>
    <li><a id="linkCadContatos" (click)="navegacao('cadContatos')">Cadastro de Contatos</a></li>
    <li><a id="linkCadFuncoes" (click)="navegacao('cadFuncoes')">Cadastro de Funções</a></li>
    <li><a id="linkCadTurnos" (click)="navegacao('cadTurnos')">Cadastro de Turnos</a></li>
    <li><a id="linkCadSecoes" (click)="navegacao('cadSecoes')">Cadastro de Seções</a></li>
    <li><a id="linkCadUsuarios" (click)="navegacao('cadUsuarios')">Cadastro de Colaboradores/Usuários</a></li>
    <li><a id="linkCadAtividades" (click)="navegacao('cadAtividades')">Cadastro de Atividades</a></li>
    <li><a id="linkCadScripts" (click)="navegacao('cadScripts')">Cadastro de Script</a></li>
    <li><a id="linkAcompanhamento" (click)="navegacao('acompanhamento')">Acompanhamento de Atividades</a></li>
</ul> -->

<div class="sidebar fechar">
    <div class="logo-details">
        <!-- <fa-icon [icon]="faBuilding"></fa-icon> -->
        <div class="logo-img">
            <img src="../../assets/logos/icone-etl2b-124x124.png" alt="logo-Etl2b">
        </div>
        <span class="logo_name">ETL2B</span>
    </div>
    <ul class="nav-links">
        <li (click)="navegacao('home')">
            <a>
                <fa-icon [icon]="faChartBar"></fa-icon>
                <span class="link_name">Dashboard</span>
            </a>
            <ul class="sub-menu blank">
                <li><a class="link_name">Dashboard</a></li>
            </ul>
        </li>
        <li (click)="espandeMenu('cadastro')" class="cadastro">
            <div class="iocn-links">
                <a>
                    <fa-icon [icon]="faAddressCard"></fa-icon>
                    <span class="link_name">Cadastros</span>
                </a>
                <fa-icon [icon]="faAngleDown" class="arrow"></fa-icon>
            </div>
            <ul class="sub-menu">
                <li><a class="link_name"href="#">Cadastros</a></li>
                <li><a (click)="navegacao('cadGrupos')">Grupo Corporativo</a></li>
                <li><a (click)="navegacao('cadEmpresas')">Empresas</a></li>
                <li><a (click)="navegacao('cadContatos')">Contatos</a></li>
                <li><a (click)="navegacao('cadFuncoes')">Funções</a></li>
                <li><a (click)="navegacao('cadTurnos')">Turnos</a></li>
                <li><a (click)="navegacao('cadSecoes')">Seções</a></li>
                <li><a (click)="navegacao('cadScripts')">Scripts</a></li>
                <li><a (click)="navegacao('cadUsuarios')">Colaboradores/Usuários</a></li>
            </ul>
        </li>
        <li (click)="navegacao('cadAtividades')">
            <a>
                <fa-icon [icon]="faClipboardList"></fa-icon>
                <span class="link_name">Atividades</span>
            </a>
            <ul class="sub-menu blank">
                <li><a class="link_name">Atividades</a></li>
            </ul>
        </li>
    </ul>
    <div class="home-content">
        <fa-icon [icon]="faBars" (click)="espandeSideBar()"></fa-icon>
    </div>
</div>
