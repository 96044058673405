<div class="container-fluid" style="height: 100vh; overflow: hidden;">
  <div class="row" style="height: 70px;">
      <app-header style="width: 100%;"></app-header>
  </div>
  <div class="row home">
      <div class="container-form">
        <div class="linha-campos">
          <form  id="formContatos" action="#" ngNativeValidate autocomplete="off">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputLoja">Loja</label>
                <ng-select [(ngModel)]="id_loja" name="id_loja" required>
                    <ng-option *ngFor="let loja of lojas" [value]="loja.id">{{loja.name}}</ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-2">
                  <label for="inputCpf">CPF</label>
                  <input [(ngModel)]="cpf" name="cpf" type="text" class="form-control" id="inputCpf" placeholder="CPF" required>
              </div>
              <div class="form-group col-md-4">
                  <label for="inputNome">Nome Contato</label>
                  <input [(ngModel)]="nome" name="nome" type="text" class="form-control" id="inputNome" placeholder="Nome Contato" required>
              </div>
              <div class="form-group col-md-4">
                  <label for="inputSetor">Setor</label>
                  <input [(ngModel)]="setor" name="setor" type="text" class="form-control" id="inputSetor" placeholder="Setor" required>
              </div>
              <div class="form-group col-md-2">
                  <label for="inputTelefone">Telefone</label>
                  <input [(ngModel)]="telefone" name="telefone" type="text" class="form-control" id="inputTelefone" placeholder="Telefone" required>
              </div>
            </div>
            <div class="controle-buttons">
              <button (click)="limparDados()" class="btn btn-success" style="margin-right: 10px;">Limpar</button>
              <button (click)="salvar()" class="btn btn-success">Adicionar/Salvar</button>
            </div>
          </form>
        </div>
        <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
        <div class="linha-dados">
          <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th style="width: 100px;">CPF</th>
                <th style="width: 200px;">Nome Contato</th>
                <th style="width: 100px;">Setor</th>
                <th style="width: 75px;">Telefone</th>
                <th style="width: 60px;">Acoes</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contato of contatos">
                <td>{{ this.formatTextApi.formatCnpjCpf(contato.cpf) }}</td>
                <td>{{ contato.nome }}</td>
                <td>{{ contato.setor }}</td>
                <td>{{ contato.telefone }}</td>
                <td class="acoes-linha">
                  <button type="button" class="btn btn-primary" (click)="editar(contato.id_contato)"><fa-icon [icon]="faEdit"></fa-icon></button>
                  <button type="button" class="btn btn-danger" (click)="modalPergunta(contato.id_contato)"><fa-icon [icon]="faTrash"></fa-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id=ModalExcluir>
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Excluir registro</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Deseja realmente excluir o registro?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
              <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</div>
