import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
import { UsuariosComponent } from './cadastros/usuarios/usuarios.component';
import { FuncoesComponent } from './cadastros/funcoes/funcoes.component';
import { GruposComponent } from './cadastros/grupos/grupos.component';
import { LojasComponent } from './cadastros/lojas/lojas.component';
import { TurnosComponent } from './cadastros/turnos/turnos.component';
import { LoadingComponent } from './loading/loading.component';
import { ListaAtividadesComponent } from './listas/lista-atividades/lista-atividades.component';
import { CriaAtividadesComponent } from './cadastros/cria-atividades/cria-atividades.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { TaskwebComponent } from './taskweb/taskweb.component';
import { PoliticaComponent } from './politica/politica.component';
import { AprovaPedidosCheckmeComponent } from './pages/aprova-pedidos-checkme/aprova-pedidos-checkme.component';
import { ListaPedidosFornecedorComponent } from './pages/lista-pedidos-fornececedor/lista-pedidos-fornecedor.component';

const routes: Routes = [
    {
        path: '',
        component: AccountComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cadGrupos',
        component: GruposComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cadEmpresas',
        component: LojasComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cadUsuarios',
        component: UsuariosComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cadFuncoes',
        component: FuncoesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'cadTurnos',
        component: TurnosComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'listaAtividades',
        component: ListaAtividadesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'criaAtividades',
        component: CriaAtividadesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'quemSomos',
        component: QuemSomosComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'taskweb',
        component: TaskwebComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'listaPedidosFornecedor',
        component: ListaPedidosFornecedorComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'pedidoSelecionado',
        component: AprovaPedidosCheckmeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'loading',
        component: LoadingComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'politica',
        component: PoliticaComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRountingModule { }