<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #959fb1; ">
    <div class="row" style="height: 70px;">
        <app-header style="width: 100%;"></app-header>
    </div>
    <div class="row home">
        <div class="container-form">
            <div class="linha-header">
                <div class="row">
                    <div class="col-md-2 button-pesquisa">
                        <button class="btn btn-primary bg-gradient" type="submit" (click)="voltar()">
                            <fa-icon [icon]="faAnglesLeft"></fa-icon>
                            <span style="margin-left: 5px;">Voltar</span>
                        </button>
                    </div>
                    <div class="col-md-8 titulo-painel">
                        <h3>Pedido</h3>
                        <div *ngIf="ativaLoading" class="spinner-border text-light loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="col-md-2 button-pesquisa">
                        <button class="btn btn-success bg-gradient" type="submit" (click)="salvarPedido()">Salvar</button>
                        <button class="btn btn-secondary bg-gradient" type="submit" (click)="aprovaPedido()">Aprovar</button>
                        <button class="btn btn-secondary bg-gradient" type="submit" (click)="reprovaPedido()">Reprovar</button>
                    </div>
                </div>
                <hr>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <label for="inputEmpresa">Empresa:</label>
                    <input [(ngModel)]="empresa" name="empresa" type="text" class="form-control" id="inputEmpresa" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputStatus">Status</label>
                    <input [(ngModel)]="listaPedidos[0].status_desc" name="status" type="text" class="form-control" id="inputStatus" [readonly]="true">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputTipoCarga">Tipo Carga</label>
                    <input [(ngModel)]="listaPedidos[0].tipo_carga" name="tipoCarga" type="text" class="form-control" id="inputTipoCarga" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputIdVendedor">Id Vendedor</label>
                    <input [(ngModel)]="listaPedidos[0].id_vendedor" name="idVendedor" type="text" class="form-control" id="inputIdVendedor" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputNomeVendedor">Nome Vendedor</label>
                    <input [(ngModel)]="listaPedidos[0].nome_vendedor" name="nomeVendedor" type="text" class="form-control" id="inputNomeVendedor" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputDataPedido">Data Pedido</label>
                    <input [(ngModel)]="listaPedidos[0].data_pedido" name="dataPedido" type="text" class="form-control" id="inputDataPedido" [readonly]="true">
                </div>
                <!-- <div class="form-group col-md-1">
                    <label for="inputDataGeracaoPedido">Data Geração Ped</label>
                    <input [(ngModel)]="listaPedidos[0].data_geracao_pedido" name="dataGeracaoPedido" type="text" class="form-control" id="inputDataGeracaoPed" [readonly]="true">
                </div> -->
                <div class="form-group col-md-2">
                    <label for="inputDataConfima">Data Confimação</label>
                    <input [(ngModel)]="listaPedidos[0].data_confirma" name="dataConfirma" type="text" class="form-control" id="inputDataConfima" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputCnpjFornecedor">Cnpj Fornecedor</label>
                    <input [(ngModel)]="listaPedidos[0].cnpj_fornecedor" name="cnpjFornecedor" type="text" class="form-control" id="inputCnpjFornecedor" [readonly]="true">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputFornecedor">Fornecedor</label>
                    <input [(ngModel)]="listaPedidos[0].razao_social_fornecedor" name="fornecedor" type="text" class="form-control" id="inputFornecedor" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputTotalItens">Quant. Itens</label>
                    <input [(ngModel)]="quantidadeItens" name="totalItens" type="text" class="form-control" id="inputTotalItens" [readonly]="true">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputIdPedido">Código Pedido</label>
                    <input [(ngModel)]="listaPedidos[0].id_pedido" name="idPedido" type="text" class="form-control" id="inputIdPedido" [readonly]="true">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputPedidoOrigem">Código Origem</label>
                    <input [(ngModel)]="listaPedidos[0].numero_pedido_origem" name="pedidoOrigem" type="text" class="form-control" id="inputPedidoOrigem" [readonly]="true">
                </div>
                <div class="form-group col-md-2">
                    <label for="inputPrazoPagamento">Cond. pagamento</label>
                    <input [(ngModel)]="listaPedidos[0].prazo_pagamento" name="prazoPagamento" type="text" class="form-control" id="inputPrazoPagamento" [readonly]="false" (keydown.enter)="onKeydown($event,'cond')">
                </div>
                <!-- <div class="form-group col-md-1">
                    <label for="inputTotalQuant">Quant. Pedido</label>
                    <input [(ngModel)]="quantidadeTotal" name="totalQuant" type="text" class="form-control" id="inputTotalQuant" [readonly]="true">
                </div>
                <div class="form-group col-md-1">
                    <label for="inputTotalValor">Valor Pedido</label>
                    <input [(ngModel)]="valorPedidoTotalStr" name="totalValor" type="text" class="form-control" id="inputTotalValor" [readonly]="true">
                </div> -->
            </div>
            <div class="tabela-dados table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th (click)="ordernar('ean')">
                                EAN DO PRODUTO
                                <span *ngIf="key=='ean'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('codigo_produto_cliente')">
                                CÓDIDO DO PRODUTO
                                <span *ngIf="key=='codigo_produto_cliente'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th style="min-width: 300px;" (click)="ordernar('descricao_produto')">
                                DESCRIAÇÃO DO PRODUTO
                                <span *ngIf="key=='descricao_produto'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('quant_ajst_multiplo')">
                                QUANT. PEDIDO
                                <span *ngIf="key=='quant_ajst_multiplo'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('preco_venda')">
                                PREÇO COMPRA R$
                                <span *ngIf="key=='preco_venda'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('total')">
                                TOTAL ITEM R$
                                <span *ngIf="key=='total'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('venda_med_diaria')">
                                VENDA MÉDIA MÊS QTD
                                <span *ngIf="key=='venda_med_diaria'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('ciclo_venda_com_estoque')">
                                CICLO DE VENDA DIAS
                                <span *ngIf="key=='ciclo_venda_com_estoque'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('margem_media')">
                                MARGEM %
                                <span *ngIf="key=='margem_media'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('capacidade')">
                                CAPACIDADE QTD
                                <span *ngIf="key=='capacidade'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('saldo_estoque')">
                                ESTOQUE
                                <span *ngIf="key=='saldo_estoque'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('ultima_entrada_sem_formato')">
                                ULT. ENTRADA
                                <span *ngIf="key=='ultima_entrada_sem_formato'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('ultima_saida_sem_formato')">
                                ULT. SAÍDA
                                <span *ngIf="key=='ultima_saida_sem_formato'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('dias_estoq_pedido')">
                                DIAS DE ESTOQUE DO PEDIDO
                                <span *ngIf="key=='dias_estoq_pedido'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('dias_estoq_pos_pedido')">
                                DIAS DE ESTOQUE FINAL
                                <span *ngIf="key=='dias_estoq_pos_pedido'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            <th (click)="ordernar('multiplo_fornecedor')">
                                MULTIPLO FORNECEDOR
                                <span *ngIf="key=='multiplo_fornecedor'">{{order=='desc' ? '▲' : '▼'}}</span>
                            </th>
                            
                            <!-- <th>Cód. no fornecedor</th>
                                <th>Total ajuste multiplo</th>
                                <th>Multiplo Venda</th>
                            <th>Fator Ajuste</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let itemPedidos of listaPedidos[0].itens | orderBy: order : key" (click)="selecionaLinha(itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente)" id="{{itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente}}" [ngClass]="itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente==idSelecionado? 'linhaSelecionada' : ''">
                            <td>{{itemPedidos.ean}}</td>
                            <td>{{itemPedidos.codigo_produto_cliente}}</td>
                            <td>{{itemPedidos.descricao_produto}}</td>
                            <td style="text-align: center" 
                                (dblclick)="cardDoubleClick('quant_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente,'quant')">
                                <span *ngIf="'quant_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente!=idSelecionadoQuant">
                                    {{itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_ajst_multiplo}}
                                </span>
                                <input *ngIf="'quant_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente==idSelecionadoQuant" 
                                        name="quantidade"
                                        style="text-align: end;"
                                        type="text" 
                                        class="form-control" 
                                        id="{{'input_quant_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente}}" 
                                        value="{{itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_ajst_multiplo}}"
                                        (keydown.enter)="onKeydown($event,'quant')"
                                        (keydown.escape)="onKeydown($event,'escape')">
                            </td>
                            <td style="text-align: center"
                                (dblclick)="cardDoubleClick('preco_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente,'preco')">
                                <span *ngIf="'preco_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente!=idSelecionadoPreco">
                                    {{(itemPedidos.preco_venda_confirmada?itemPedidos.preco_venda_confirmada:itemPedidos.preco_venda).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                </span>
                                <input *ngIf="'preco_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente==idSelecionadoPreco" 
                                        name="preco"
                                        style="text-align: end;"
                                        type="number" 
                                        class="form-control" 
                                        id="{{'input_preco_'+itemPedidos.id_pedido+itemPedidos.codigo_produto_cliente}}" 
                                        value="{{itemPedidos.preco_venda}}"
                                        (keydown.enter)="onKeydown($event,'preco')"
                                        (keydown.escape)="onKeydown($event,'escape')">
                            </td>
                            <td style="text-align: center">{{((itemPedidos.preco_venda_confirmada?itemPedidos.preco_venda_confirmada:itemPedidos.preco_venda)*(itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_ajst_multiplo)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                            <td style="text-align: center">{{itemPedidos.venda_med_diaria.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                            <td style="text-align: center">{{itemPedidos.ciclo_venda_com_estoque}}</td>
                            <td style="text-align: center">{{itemPedidos.margem_media.toFixed(2)}}</td>
                            <td style="text-align: center">{{itemPedidos.capacidade_confirmada?itemPedidos.capacidade_confirmada:itemPedidos.capacidade}}</td>
                            <td style="text-align: center">{{itemPedidos.estoque_confirmado?itemPedidos.estoque_confirmado:itemPedidos.saldo_estoque}}</td>
                            <td>{{itemPedidos.ultima_entrada}}</td>
                            <td>{{itemPedidos.ultima_saida}}</td>
                            <td style="text-align: center">{{itemPedidos.dias_estoq_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                            <td style="text-align: center">{{itemPedidos.dias_estoq_pos_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                            <td style="text-align: center">{{itemPedidos.multiplo_fornecedor}}</td>
                            
                            <!-- <td>{{itemPedidos.id_codigo_produto_fornecedor}}</td>
                                <td style="text-align: center">{{itemPedidos.total_ajst_mtp_conf}}</td>
                                <td style="text-align: center">{{itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_pedido}}</td>
                            <td style="text-align: center">{{itemPedidos.fator_ajuste}}</td> -->
                        </tr>
                    </tbody>
                    <tfoot style="--bs-table-bg: #6E7D81; --bs-table-color: #fff">
                        <tr style="--bs-table-bg: #6E7D81; --bs-table-color: #fff">
                            <td></td>
                            <td>Qtd. Itens: {{quantidadeItens}}</td>
                            <td></td>
                            <td style="text-align: center">{{quantidadeTotal}}</td>
                            <td></td>
                            <td style="text-align: center">{{valorPedidoTotalStr}}</td>
                            <td colspan="15"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <!-- <div class="tabela-dados table-responsive">
                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th>Cód. Pedido</th>
                            <th>status</th>
                            <th>Data</th>
                            <th>Data Confirmado</th>
                            <th>Fornecedor</th>
                            <th>Nome Fornecedor</th>
                            <th>Aprova / Reprova</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of listaPedidos">
                        <tr (click)="escondeInfo('linha'+item.id_pedido)">
                            <td>{{item.id_pedido}}</td>
                            <td>{{item.status_pedido}}</td>
                            <td>{{item.data_pedido}}</td>
                            <td>{{item.data_confirma}}</td>
                            <td>{{item.cnpj_fornecedor}}</td>
                            <td>{{item.razao_social_fornecedor}}</td>
                            <td></td>
                        </tr>

                        <tr id="linha{{item.id_pedido}}" class="esconde">
                            <td colspan="7">
                                <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Cód. no fornecedor</th>
                                            <th>Cód. produto</th>
                                            <th>Ean</th>
                                            <th>Descriação prod.</th>
                                            <th>Ult. saída</th>
                                            <th>Ult. entrada</th>
                                            <th>Ciclo venda com estoque</th>
                                            <th>Preço de venda</th>
                                            <th>Venda média diária</th>
                                            <th>Quant. Pedido</th>
                                            <th>Dias de estoque</th>
                                            <th>Dias estoque pós Pedido</th>
                                            <th>Quant. Multiplo</th>
                                            <th>Total</th>
                                            <th>Total ajuste multiplo</th>
                                            <th>Capacidade</th>
                                            <th>Estoque</th>
                                            <th>Multiplo Fornecedor</th>
                                            <th>Fator Ajuste</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let itemPedidos of item.itens">
                                        <tr>
                                            <td>{{itemPedidos.id_codigo_produto_fornecedor}}</td>
                                            <td>{{itemPedidos.codigo_produto_cliente}}</td>
                                            <td>{{itemPedidos.ean}}</td>
                                            <td>{{itemPedidos.descricao_produto}}</td>
                                            <td>{{itemPedidos.ultima_saida}}</td>
                                            <td>{{itemPedidos.ultima_entrada}}</td>
                                            <td>{{itemPedidos.ciclo_venda_com_estoque}}</td>
                                            <td>{{itemPedidos.preco_venda.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                                            <td>{{itemPedidos.venda_med_diaria.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.quant_pedido_confirmada?itemPedidos.quant_pedido_confirmada:itemPedidos.quant_pedido}}</td>
                                            <td>{{itemPedidos.dias_estoq_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.dias_estoq_pos_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                                            <td>{{itemPedidos.quant_ajst_multiplo_confirmada?itemPedidos.quant_ajst_multiplo_confirmada:itemPedidos.quant_ajst_multiplo}}</td>
                                            <td>{{itemPedidos.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                                            <td>{{itemPedidos.total_ajst_mtp_conf}}</td>
                                            <td>{{itemPedidos.capacidade_confirmada?itemPedidos.capacidade_confirmada:itemPedidos.capacidade}}</td>
                                            <td>{{itemPedidos.estoque_confirmado?itemPedidos.estoque_confirmado:itemPedidos.saldo_estoque}}</td>
                                            <td>{{itemPedidos.multiplo_fornecedor}}</td>
                                            <td>{{itemPedidos.fator_ajuste}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            
            <!-- <p-table [value]="pedidoItens.itens" dataKey="pedidoItens.id_pedido" 
                [scrollable]="true" scrollHeight="400px"
                styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width:150px">Cód. no fornecedor</th>
                        <th style="min-width:105px">Cód. produto</th>
                        <th style="min-width:145px">Ean</th>
                        <th style="min-width:400px">Descriação prod.</th>
                        <th style="min-width:100px">Ult. saída</th>
                        <th style="min-width:100px">Ult. entrada</th>
                        <th style="min-width:110px">Ciclo venda com estoque</th>
                        <th style="min-width:100px">Preço de venda</th>
                        <th style="min-width:100px">Venda média diária</th>
                        <th style="min-width:100px">Quant. Pedido</th>
                        <th style="min-width:100px">Dias de estoque</th>
                        <th style="min-width:120px">Dias estoque pós Pedido</th>
                        <th style="min-width:100px">Quant. Multiplo</th>
                        <th style="min-width:100px">Total</th>
                        <th style="min-width:100px">Total ajuste multiplo</th>
                        <th style="min-width:100px">Capacidade</th>
                        <th style="min-width:100px">Estoque</th>
                        <th style="min-width:100px">Multiplo Fornecedor</th>
                        <th style="min-width:100px;">Fator Ajuste</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-itens>
                    <tr>
                        <td>{{itens.id_codigo_produto_fornecedor}}</td>
                        <td>{{itens.codigo_produto_cliente}}</td>
                        <td>{{itens.ean}}</td>
                        <td>{{itens.descricao_produto}}</td>
                        <td>{{itens.ultima_saida}}</td>
                        <td>{{itens.ultima_entrada}}</td>
                        <td style="text-align: center">{{itens.ciclo_venda_com_estoque}}</td>
                        <td style="text-align: center">{{itens.preco_venda.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                        <td style="text-align: center">{{itens.venda_med_diaria.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                        <td style="text-align: center">{{itens.quant_pedido_confirmada?itens.quant_pedido_confirmada:itens.quant_pedido}}</td>
                        <td style="text-align: center">{{itens.dias_estoq_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                        <td style="text-align: center">{{itens.dias_estoq_pos_pedido.toLocaleString(undefined, {minimumFractionDigits: 0})}}</td>
                        <td style="text-align: center">{{itens.quant_ajst_multiplo_confirmada?itens.quant_ajst_multiplo_confirmada:itens.quant_ajst_multiplo}}</td>
                        <td style="text-align: center">{{itens.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                        <td style="text-align: center">{{itens.total_ajst_mtp_conf}}</td>
                        <td style="text-align: center">{{itens.capacidade_confirmada?itens.capacidade_confirmada:itens.capacidade}}</td>
                        <td style="text-align: center">{{itens.estoque_confirmado?itens.estoque_confirmado:itens.saldo_estoque}}</td>
                        <td style="text-align: center">{{itens.multiplo_fornecedor}}</td>
                        <td style="text-align: center">{{itens.fator_ajuste}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">There are no order for this product yet.</td>
                    </tr>
                </ng-template>
            </p-table> -->
        </div>
    </div>
</div>