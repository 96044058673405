import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators'

import { FormatDate } from 'src/app/util/format-date';

import Swal from 'sweetalert2';
import { IPedido } from '../../models/IPedidos';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { ListaPedidosFornecedorService } from './service/lista-pedidos-fornecedor.service';

declare let $: any;

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IFornecedores = {
  id_fornecedor: string;
  cnpj: string;
  razao: string;
  fantasia: string;
}

type IStatus = {
  id: string;
  descricao: string;
}

type IFiltros = {
  empresa: string;
  fornecedor: string;
  status: string;
  dataDe: string;
  dataAte: string;
}

@Component({
  selector: 'app-lista-pedidos-fornecedor',
  templateUrl: './lista-pedidos-fornecedor.component.html',
  styleUrls: ['./lista-pedidos-fornecedor.component.css']
})
export class ListaPedidosFornecedorComponent {

  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  pesquisa: string;
  statusSelecionado: string = 'T';
  origemSelecionado: string = 'G';
  ativaLoading: boolean = true;
  filtros: IFiltros;
  key: string = 'data_pedido_sem_formato';
  order: string = 'desc';
  matMenuTimer: any;


  input$ = new Subject<string>();
  loading = false;
  termoEscrito = '';

  empresasGeral: IEmpresas[] = [];
  empresaSelecionado: string = '';
  fornecedores: IFornecedores[] = [];
  fornecedoresBuffer: IFornecedores[] = [];
  fornecedoresGeral: IFornecedores[] = [];
  fornecedorSelecionado: string = '73909566000104';

  listaPedidos: IPedido[] = [];
  listaPedidosGeral: IPedido[] = [];
  listaStatus: IStatus[] = [{id: 'T', descricao: 'Todos'},{id: 'I', descricao: 'Importado'},{id:'P',descricao:'Pendente'},{id: 'A',descricao: 'Aprovado'},{id: 'R',descricao: 'Reprovado'}]
  listaOrigem: IStatus[] = [{id: 'G', descricao: 'Todos'},{id: 'T', descricao: 'Terus'},{id: 'C', descricao: 'Cliente'}]

  idSelecionado: string = '';

  bufferSize = 50;

  faRotateRight = faRotateRight;



  constructor(
    private router: Router,
    private apiListaPedidos: ListaPedidosFornecedorService,
    private formatDate: FormatDate,
  ) { }

  ngOnInit(): void {
    this.ativaLoading = true;

    this.carregaFiltros();
    this.onSearch();

  }

  async carregaFiltros(){

    this.filtros = JSON.parse(sessionStorage.getItem('filtros'));
    if(this.filtros){
      this.dataInicial = this.filtros.dataDe;
      this.dataFinal = this.filtros.dataAte;
      this.empresaSelecionado = this.filtros.empresa;
      this.fornecedorSelecionado = this.filtros.fornecedor;
      this.statusSelecionado = this.filtros.status;
    }else{
      this.dataInicial = this.formatDate.formatDateForInput(new Date(), 30);
      this.dataFinal = this.formatDate.formatDateForInput(new Date(), 0);
      this.statusSelecionado = 'T';
    }

    await this.apiListaPedidos.listaOpcoes(this.empresaSelecionado).then(result =>{
      this.empresasGeral = result.empresas;

      if(this.empresaSelecionado==''){
        this.empresaSelecionado = this.empresasGeral[0].id_cliente+'_'+this.empresasGeral[0].id_empresa+'_'+this.empresasGeral[0].id_loja_empresa;
      }

      this.fornecedores = result.fornecedores;
      this.fornecedoresBuffer = this.fornecedores.slice(0, this.bufferSize);

      this.carregaDados();
      
    })

    this.atualizaFiltros();
  }

  onSearch() {
    this.input$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.fakeService(term))
    )
      .subscribe(data => {
        if(this.fornecedoresBuffer.length <= this.bufferSize){
          this.fornecedoresBuffer.push(data);
        }
      })
  }

  private fakeService(term) {
    this.fornecedoresBuffer = [];
    if(term){
      this.termoEscrito = term;
      return this.fornecedores.filter(x => x.razao.includes(term.toUpperCase()) || x.cnpj.includes(term.toUpperCase()));
    }else{
      if(this.fornecedorSelecionado){
        this.carregaDados();
      }
      this.termoEscrito = '';
      return this.fornecedores;
    }
  }

  fetchMore(term) {
    const len = this.fornecedoresBuffer.length;
    const more = this.fornecedores.filter(x => x.razao.includes(this.termoEscrito.toUpperCase()) || x.cnpj.includes(this.termoEscrito.toUpperCase())).slice(len, this.bufferSize + len);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.fornecedoresBuffer = this.fornecedoresBuffer.concat(more);
    }, 200);
  }

  carregaDados(){
    this.ativaLoading = true;

    if(this.fornecedorSelecionado && this.empresaSelecionado){
      this.apiListaPedidos.ListasPedidos(this.empresaSelecionado,this.fornecedorSelecionado, this.dataInicial, this.dataFinal, this.statusSelecionado).then(result => {
        if(result.length<=0){
          this.listaPedidosGeral = [];
          this.listaPedidos = [];
          this.idSelecionado = '';
        }else{
          this.listaPedidosGeral = result;
          this.listaPedidos = result;
          this.idSelecionado = this.listaPedidos[0].id_pedido;
        }
        this.ativaLoading = false;

        if(this.origemSelecionado==="T"){
          this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem===" ");
        }else if(this.origemSelecionado==="C"){
          this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem.trim().length> 1);
        }else if(this.origemSelecionado==="G"){
          this.listaPedidos = this.listaPedidosGeral;
        }

        this.Toast.fire({
          icon: this.idSelecionado?'success':'warning',
          title: this.idSelecionado?'Pedidos Encontrados':'Nenhum pedido encontrado!',
        });
      });
    }else{
      this.Toast.fire({
        icon: 'warning',
        title: 'Selecione um fornecedor!'
      });
      this.ativaLoading = false;
    }

    this.atualizaFiltros();
  }

  atualizaPesquisa(){
    this.carregaDados();
  }

  selecionaLinha(id: string){
    this.matMenuTimer = setTimeout( () => {this.clickedMe();}, 300); 
    this.idSelecionado = id;
  }

  visualizaPedido(){
    if(this.idSelecionado){
      let empresaEnv = this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].id_loja_empresa +' - '+ this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].descricao;
      this.router.navigate(['/pedidoSelecionado'], { queryParams: { idPedido: this.idSelecionado, empresa: empresaEnv } } );
    }
  }

  aprovaPedido(){
    let pedido = this.listaPedidos.filter(e => e.id_pedido == this.idSelecionado)[0];
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiListaPedidos.setPedidos(this.idSelecionado,'A').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      });
      this.carregaDados();
    });
  }

  reprovaPedido(){
    let pedido = this.listaPedidos.filter(e => e.id_pedido == this.idSelecionado)[0];
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiListaPedidos.setPedidos(this.idSelecionado,'R').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      });
      this.carregaDados();
    });
  }

  filtraDados(id:string){
    if(id==="origem"){
      if(this.origemSelecionado==="T"){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem===" ");
      }else if(this.origemSelecionado==="C"){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem.trim().length> 1);
      }else if(this.origemSelecionado==="G"){
        this.listaPedidos = this.listaPedidosGeral;
      }
    }else{
      
      if(this.pesquisa){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.cnpj_fornecedor.indexOf(this.pesquisa.toUpperCase())>-1 || e.razao_social_fornecedor.indexOf(this.pesquisa.toUpperCase())>-1 || e.status_desc.indexOf(this.pesquisa.toUpperCase())>-1 || e.id_pedido.toUpperCase().indexOf(this.pesquisa.toUpperCase())>-1);
      }else{
        this.listaPedidos = this.listaPedidosGeral;
      } 
    }

  }

  atualizaFiltros(){
    this.filtros = {
      dataDe: this.dataInicial,
      dataAte: this.dataFinal,
      empresa: this.empresaSelecionado,
      fornecedor: this.fornecedorSelecionado,
      status: this.statusSelecionado
    }

    sessionStorage.setItem('filtros', JSON.stringify(this.filtros));
  }

  ordernar(key){
    if(key==this.key)
      this.order = this.order=='asc'?'desc':'asc';
    else{
      this.order = 'asc';
    }
    this.key = key;
  }

  cardDoubleClick(id:string): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    this.idSelecionado = id;

    let empresaEnv = this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].id_loja_empresa +' - '+ this.empresasGeral.filter(e => e.id_cliente+'_'+e.id_empresa+'_'+e.id_loja_empresa==this.empresaSelecionado)[0].descricao;
    this.router.navigate(['/pedidoSelecionado'], { queryParams: { idPedido: this.idSelecionado, empresa: empresaEnv } } );
  }

  clickedMe() {
    if (!this.matMenuTimer) return;
  }
}
